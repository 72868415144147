import * as THREE from 'three'
import store from '../store'
import { bounds } from '../utils'
import WebGlManager from './WebGlManager'


export default class GLObject extends THREE.Object3D {
  constructor() {
    super()

    this.manager = new WebGlManager()
    this.sizes = this.manager.sizes
    this.isResizing = false

    
  }

  init(el) {
    this.el = el
    this.offsetParent = el.offsetParent
    this.parentNode = this.el.parentNode.parentNode
    this.resize()
  }

  getOffset(element) {
    var offsetLeft = 0;
    var offsetTop  = 0;
    
    while (element)
    {
        offsetLeft += element.offsetLeft;
        offsetTop  += element.offsetTop;
    
        element = element.offsetParent;
    }
    
    return {x: offsetLeft, y:offsetTop};
  }

  resize() {


    if(!this.el) return
    this.isResizing = true
    this.rect =  this.offsetParent ?  bounds(this.offsetParent) : bounds(this.parentNode)
    this.offset = this.getOffset(this.el)

    this.final =  {
      left: this.offset.x,
      top: this.offset.y,
      width: this.rect.width,
      height: this.rect.height,
    }

    this.bounds = {
      left: this.offset.x,
      top: this.rect.top + window.scrollY,
      width: this.rect.width,
      height: this.rect.height,
    }



    this.updateSize()
    this.updatePosition()

    this.isResizing = false
  }

  updateSize() {
    this.viewSize = this.manager.getViewSize()

    // Set size
    const x = this.bounds.width /  this.sizes.width
    const y = this.bounds.height /  this.sizes.height

    this.scale.x = this.viewSize.width * x
    this.scale.y = this.viewSize.height * y
  }

  updatePosition(current, last) {

    if(this.isResizing ) return

    this.position.x = -(this.viewSize.width / 2) + this.scale.x / 2
    this.position.x += ((this.bounds.left) /  this.sizes.width) * this.viewSize.width   
    this.position.y = this.viewSize.height / 2 - this.scale.y / 2
    this.position.y -= (( this.bounds.top - current ) /  this.sizes.height ) * this.viewSize.height

  }

  updateDOM(el) {
    this.el = el
    this.offsetParent = el.offsetParent
    this.parentNode = el.parentNode.parentNode
    this.resize()
  }

  update() {}
  
}
