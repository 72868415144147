import * as THREE from 'three'
import store from '../store'
import { Core } from '../core'
import WebGlManager from './WebGlManager'
import GLObject from './GLObject'
import { qsa, lerp } from '../utils'
import gsap from 'gsap'

const hPlaneGeometry = new THREE.PlaneGeometry(1, 1)

const vertex = `
  precision mediump float;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

const fragment = `
  #define PI 3.14159
  #define OCTAVES 6
  precision mediump float;
  varying vec2 vUv;
  uniform float uHover;
  uniform float uAlpha;
  uniform sampler2D uTexture;
  uniform sampler2D uDisplacement;
  uniform float uTime;
  uniform vec2 uRes;
  uniform vec2 uMouse;
  uniform vec2 uPlaneSize;
  uniform vec2 uTextureSize;

  float random(vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
  }

  float noise(vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));
    vec2 u = f * f * (3.0 - 2.0 * f);
    return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
  }
  
  vec2 randomDirection(vec2 st) {
    float angle = random(st) * 2.0 * 3.141592653589793;
    return vec2(cos(angle), sin(angle));
  }

  float rectangle(in vec2 _st, in vec2 _size, in float blurriness){
    vec2 half_size = _size * 0.5;
    vec2 dist = abs(_st) - half_size;
    float outside = length(max(dist, 0.0));
    float inside = min(max(dist.x, dist.y), 0.0);
    return 1.0 - smoothstep(0.0, blurriness, outside + inside);
  }

  float random2(float x, float y, float t) {
    return fract(sin(dot(vec3(x, y, t), vec3(12.9898, 78.233, 45.164))) * 43758.5453);
  }

  // Function to get the opacity of each cell based on its position in the grid
  float getCellOpacity(int row, int col, float time) {
    float randValue = random2(float(row), float(col), time * 0.1);
    return step(0.5, randValue); // Returns 1.0 if randValue >= 0.5, else 0.0
  }

  vec2 imageCover( vec2 uv, vec2 planeSize, vec2 textureSize  ) {

    float cw = planeSize.x;
    float ch = planeSize.y;
    float iw = textureSize.x;
    float ih = textureSize.y;

    float rw = cw / iw;
    float rh = ch / ih;

    float planeAspect = planeSize.x / planeSize.y;
    float textureAspect = textureSize.x / textureSize.y;
    float scaleFactor = planeAspect / textureAspect;

    if(rw>rh) {
      uv.y = uv.y * scaleFactor + (1.0 - scaleFactor) * 0.5;
    } else {
      uv.x = uv.x * scaleFactor + (1.0 - scaleFactor) * 0.5;
    }

    return uv;
  }

  vec2 uvCover(vec2 uv, vec2 size, vec2 resolution) {
    vec2 coverUv = uv;
    vec2 s = resolution; // Screen
    vec2 i = size; // Image

    float rs = s.x / s.y;
    float ri = i.x / i.y;
    vec2 new = rs < ri ? vec2(i.x * s.y / i.y, s.y) : vec2(s.x, i.y * s.x / i.x);
    vec2 offset = (rs < ri ? vec2((new.x - s.x) / 2.0, 0.0) : vec2(0.0, (new.y - s.y) / 2.0)) / new;
    
    coverUv = coverUv * s / new + offset;

    return coverUv;
  }


  void main() {


    vec2 uv = uvCover(vUv, uTextureSize, uPlaneSize);
    vec2 res = uRes;
    

    vec2 st = gl_FragCoord.xy / res.xy - vec2(0.5);
    vec4 color = vec4(0.7);
    st.y *= uRes.y / uRes.x;
    vec2 mouse = uMouse * -0.5;
    mouse.y *= uRes.y / uRes.x;
    mouse *= -1.;

    vec2 mousePos = st + mouse;
   

    float grid = 16.0 /128.0;
    float staticTime = random(vec2(10.0,20.0));

    vec2 gridUV = vec2( floor(uv.x / grid),  floor(uv.y / grid)  );

    float staticNoise = noise(gridUV + staticTime);
    float noise = step(0.5, staticNoise);

    float displacementFactor = 0.15;
    float displacement_1 = (noise * 2.0 - 1.0) * uHover * displacementFactor; 
    float displacement_2 = (noise * 2.0 - 1.0) * staticTime * displacementFactor; 

    vec2 direction = randomDirection(gridUV);
    vec2 displacedUV_1 = uv + direction * displacement_1;
    vec2 displacedUV_2 = uv + direction * displacement_2;

    vec4 originalTexture = texture2D(uTexture, uv);
    vec4 color_0 = texture2D(uTexture, uv);
    vec4 color_1 = texture2D(uTexture, displacedUV_1);
    vec4 color_2 = texture2D(uTexture, displacedUV_2);

    float transitionSpeed = 0.5; 
    float invertStrength = abs(sin(uHover * transitionSpeed));
    vec4 finalColor = mix(color_1, vec4(1.0 - color_1.rgb, color_1.a), noise * invertStrength);

    vec2 rectSize = vec2(0.12, 0.1); // Smaller size for the entire grid
    vec2 rectPos = mouse; 
    vec2 rectMin = rectPos - rectSize * 0.5;
    vec2 rectMax = rectPos + rectSize * 0.5;



    gl_FragColor = vec4(color_1.rgb, (1. - uHover + 0.5) * uAlpha );
    //gl_FragColor = color_0;
  }
`

export default class SliderGL extends GLObject {
  constructor(index, opts = {}) {
    super()
    this.index = index
    this.opts = opts
    this.name = 'projects'
    this.manager = new WebGlManager()
    this.core = new Core()
    this.taxi = this.core.taxi
    this.time = this.manager.time
    this.scene = this.manager.scene
    this.resources = this.manager.resources
    this.state = {}
    this.state.posY = 0
    this.state.posX = 0
    this.time = 0
    this.mouse = { x: 0, y: 0 }
    this.total = this.opts.total - 1


  }

  init(el) {
    super.init(el)
    this.containerSize = new THREE.Vector2(el.clientWidth, el.clientHeight)
    this.createFrontMesh()
    this.onEnter()
  }

  createFrontMesh() {
    const black = [0.0, 0.0, 0.0, 0.0]
    const white = [1., 1., 1., 1.]
    const vec4Data = this.index.color == 'black' ? black : white
    const index = isNaN(this.index)
    const dataTexture = index ? new THREE.DataTexture(
      new Float32Array(vec4Data), // Data array
      1, // Width
      1, // Height
      THREE.RGBAFormat, // Format
      THREE.FloatType // Type of data
    ) 
    : null

    if(dataTexture) dataTexture.needsUpdate = true
    this.texture = dataTexture ? dataTexture : this.resources.items[`slide${this.index}`]
    if(this.texture) this.texture.minFilter = THREE.NearestFilter
    const displacementMap = this.resources.items.displacementMap


    this.texture.wrapS = THREE.LinearFilter;
    this.texture.wrapT = THREE.LinearFilter;
    this.texture.minFilter = THREE.LinearFilter;
    


    this.uniforms =  {
      uTexture: { value: this.texture },
      uSquares: { value: 1 },
      uHover: { value: 1 },
      uAlpha: { value: 0 },
      uTime: { value: this.time },
      uDisplacement: { value: displacementMap },
      uRes: {value:new THREE.Vector2( store.sizes.vw, store.sizes.vh )},
      uMouse: { value: new THREE.Vector2(0, 0) },
      uPlaneSize: { value: new THREE.Vector2(this.containerSize.x, this.containerSize.y) },
      uTextureSize: { value: new THREE.Vector2(this.texture.image.width, this.texture.image.height) }
    }


    this.material = new THREE.ShaderMaterial({
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: this.uniforms,
      transparent: true,
      defines: {
        PR: window.devicePixelRatio.toFixed(1)
    }
    })

    this.mesh = new THREE.Mesh(hPlaneGeometry, this.material)
    this.add(this.mesh)
  }

  resize() {
    super.resize()


    this.containerSize = new THREE.Vector2(this.el.clientWidth, this.el.clientHeight)
    //this.bounds.top = this.bounds.top - this.state.posY

    if(this.uniforms) {
      this.uniforms.uPlaneSize.value = new THREE.Vector2(this.containerSize.x, this.containerSize.y)
      this.uniforms.uRes.value = new THREE.Vector2( store.sizes.vw, store.sizes.vh )
    }
   

  }

  onEnter() {
    this.scene.add(this)
    gsap.to(this.uniforms.uAlpha, { value: 1, duration: 0.5, delay: 0.5})
  }

  animateOut() {
    gsap.to(this.uniforms.uAlpha, {
      value: 0,
      duration: 0.25,
      onComplete: () => {
        this.onLeave()
      }
    })
  }

  onLeave() {
    this.position.y = 0
    this.scene.remove(this)
  }

  getPos(scroll) {
    const { breakpoints } = store
    // let temp1 = (scroll - this.viewSize.height ) % ( this.viewSize.height * 8) + this.viewSize.height
    // let temp2 = (scroll + this.viewSize.height ) % ( this.viewSize.height * 8) - this.viewSize.height
    // return scroll < 0 ? temp1 : temp2
    //let temp1 = (scroll - this.viewSize.height ) % ( this.viewSize.height * 8) + this.viewSize.height

    const padding = breakpoints.M_UP ? 80 : 120
    const space = padding / this.sizes.width
    const offset = this.viewSize.width * space
    const height = this.scale.y + offset

    let y = scroll 
    y = gsap.utils.wrap( ( (height * this.total) - height ), - (height + height), y);

    
    return y
  }

  updatePosition(current, last) {
    //For bg
    // let scroll =  this.state.posY 
    // let y = this.viewSize.height / 2 - this.scale.y / 2
    // y -= (( (this.bounds.top) + (scroll) ) / this.sizes.height ) * this.viewSize.height
    // this.position.y = this.getPos(y)


    let scroll = store.infiniteLoop
    let y = this.viewSize.height / 2 - this.scale.y / 2
    y -= (( (this.bounds.top) + (scroll) ) / this.sizes.height ) * this.viewSize.height
    
    this.position.y = this.getPos(y)

  }

  update(obj) {
    const { sizes } = store   

    this.mouse.x  = lerp(this.mouse.x,(obj.mouse.x/sizes.vw) * 2 - 1, 0.05)
    this.mouse.y = lerp(this.mouse.y, -(obj.mouse.y / sizes.vh) * 2 + 1, 0.05)


    this.time += 1 / 300
    this.uniforms.uTime.value = this.time
    this.uniforms.uMouse.value.copy(this.mouse)
  
  }
}
