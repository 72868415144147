
import store from '../store';
import Stick from './Stick';
import { bounds, qsa } from '../utils';
import WebGlManager from "../webgl/WebGlManager"
import gsap  from 'gsap';


export default class GalleryProjects extends Stick {

  constructor(obj) {
    super(obj)

    this.manager = new WebGlManager()
    this.els = this.manager.world.projects
    this.bgs = this.manager.world.backgrounds
    this.state.index = 0


  }

  setup() {
    super.setup()
   
    const { items } = this.dom
    const rect = bounds(items[0])
    const totalItems = items.length 
    const fullWidth = bounds(items[0].parentNode).width
    const itemsWidth = []

    items.forEach( (item, i) => {
      const rect = bounds(item)
      const obj = { width: rect.width}
      itemsWidth.push(obj )
    })
    
    this.settings.totalItems = totalItems
    this.settings.width = rect.width
    this.settings.fullWidth = fullWidth - store.sizes.vw + 10
    this.settings.itemsWidth = itemsWidth
    this.bounds = this.calculateProgressRanges(itemsWidth)

  }

  beforeEnter(self) {
    super.beforeEnter(self)

    if(this.els && this.els[0]) {
      this.els[0].material.uniforms.uHover.value = 1 - this.settings.enter
    }

  }

  roundToTwoDecimalPlaces(num) {
    const epsilon = Number.EPSILON;
    const smallValue = 1e-3;  // A small value to ensure proper rounding
    return Math.round((num + epsilon + smallValue) * 100) / 100;
  }

  onUpdate (self) {
    
    super.onUpdate(self)
    const { items } = this.dom

    gsap.set(items, { x: -self.progress * this.settings.fullWidth })

    

    if(this.els && this.els[0]) {

      this.bounds.forEach( (bounds, i) => {

        const mapedProgress = this.mapValueToRange(self.progress, bounds)
        const id = bounds.index 
      
        if(this.els[id]) this.els[id].material.uniforms.uHover.value = 1 - mapedProgress
      
        if(bounds.mid > self.progress && bounds.start50 < self.progress) {
          this.state.index = bounds.index
        }
  
        if(self.progress > bounds.end) {
          if(this.els[id]) this.els[id].material.uniforms.uHover.value = 0
        }
      })
      

      this.els.forEach( (el)=> {
        if(el.name == 'background') return
        el.state.posX = -this.settings.posX
        el.state.posY = this.settings.posY
        el.state.hover = false
      })
    }

   

    if(this.bgs && this.bgs[0]) {
      this.bgs[0].state.posY = this.settings.posY
    }

  }


  mapValueToRange(value, range) {
    if (value < range.start75) return value - range.start75;
    if (value > range.start25) return 1;
    return (value - range.start75) / (range.start25 - range.start75);
  }

  calculateProgressRanges(slides) {

    const { totalItems } = this.settings
    const screenWidth = store.sizes.vw + 10

    // Calculate the total width of all slides
    const totalWidth = slides.reduce((sum, slide) => sum + slide.width, 0);
    const slideWidth = slides[totalItems - 1].width;
    // Calculate the offset as half the screen width
    const screenOffset = 0;

    let accumulatedWidth = 0;
    
    return slides.map((slide, index) => {
        const start = (accumulatedWidth - screenOffset) / (totalWidth - slideWidth);
        accumulatedWidth += slide.width;
        const end = (accumulatedWidth - screenOffset) / (totalWidth - slideWidth);
        
        // Calculate the mid and offset (75%) values
        const mid = start + (end - start) / 4;
        const offset75 = start + (end - start) * 0.75;
        const offset25 = start + (end - start) * 0.25;
        const preMidOffset = start - (end - start);
        const preOffset75 = start - (end - start) * 0.75;
        const preOffset25 = start - (end - start) * 0.25;
        
        return {
            index: index,
            start: Math.max(0, start), // Ensure start does not go below 0
            mid: Math.max(0, mid),     // Ensure mid does not go below 0
            offset25: Math.max(0, offset25), // Ensure offset does not go below 0
            offset75: Math.max(0, offset75), // Ensure offset does not go below 0
            end: Math.min(1, end),      // Ensure end does not go above 1
            start50: Math.max(0, preMidOffset),
            start75: Math.max(0, preOffset75),
            start25: Math.max(0, preOffset25),
        };
    });
  }
} 