import * as THREE from 'three'
import WebGlManager from './WebGlManager'

export default class Renderer {
  constructor() {
    this.manager = new WebGlManager()
    this.canvas = this.manager.canvas
    this.sizes = this.manager.sizes
    this.scene = this.manager.scene
    this.camera = this.manager.camera
   this.setInstance()
  }

  setInstance() {
    
    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true,
    })

    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(this.sizes.setPixelRatio)
    this.instance.setClearColor('#000000', 0.0)
    //this.instance.outputEncoding = THREE.sRGBEncoding
    //this.instance.toneMapping = THREE.ACESFilmicToneMapping
    //this.instance.shadowMap.enabled = true
  }

  resize() {
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(this.sizes.setPixelRatio)
  }

  update() {
    this.instance.render(this.scene, this.camera.instance)
    this.instance.autoClearColor = false
    this.instance.autoClearColor = true
  }
}
