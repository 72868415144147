import store from '../store'
import gsap from 'gsap'
import { qs, qsa } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { CustomEase } from '../vendor/CustomEase'


export const animateScroll = (animation) => {

  const { breakpoins } = store
  const mm = gsap.matchMedia();
  const custom =  CustomEase.create('custom', 'M0,0 C0.1,0 0.148,0.034 0.182,0.136 0.216,0.238 0.239,0.685 0.448,0.886 0.55,0.984 0.704,1 1,1')
  const studio = qs('.is-studio')

  switch (animation) {

    case 'hero':


    if(studio) {
      gsap.from('.hero-image', {
        scrollTrigger: {
          trigger: '.hero', 
          start: 'top center',
          end: 'bottom bottom',
          scrub: true,
        },
        scale: 0.9,
      })

      gsap.from('.hero-image .image-crop', {
        scrollTrigger: {
          trigger: '.hero', 
          start: 'top center',
          end: 'bottom bottom',
          scrub: true,
        },
        scale: 1.1,
      })
    }

    break

    case 'reel':

      const reel = qs('.reel')
      const boxContent = qs('.reel .box-content')

      if(!reel) return

      let reelTL

      mm.add("(min-width: 750px)", ()=> {
        reelTL = gsap.timeline({
          scrollTrigger: {
            trigger: '.reel',
            start: 'top top+=25%',   
          },
        })
      })

      mm.add("(max-width: 749px)", ()=> {
        reelTL = gsap.timeline({
          scrollTrigger: {
            trigger: '.reel',
            start: 'top center+=25%',   
            
          },
        })
      })

      if(boxContent) {
        reelTL.from('.reel .box-content', {duration: 1, x: '-101%', ease: custom })
        reelTL.from('.reel .box-inner', {duration: 1, x: '101%', ease: custom }, '-=1')
        reelTL.from('.reel .box-items', {duration: 1, x: '-101%', ease: custom },'-=0.6')
        reelTL.from('.reel .items-inner', {duration: 1, x: '101%', ease: custom }, '-=1')
      }

     
    break

    case 'narrative':

      const lines = qsa('.narrative .narrative-Line')

      if(!lines[0]) return

      lines.forEach( (line)=> {

        const chars = qsa('.char-0', line)
        const bg = qs('.bg', line)

        if(bg) {
          gsap.from(bg, {
            scrollTrigger: {
              trigger: line, 
              start: 'top center+=22%',
            },
            duration: 0.8,
            ease: custom,
            scaleY: 0,
          })
        }
      
        gsap.from(chars, {
          scrollTrigger: {
            trigger: line, 
            start: 'top center+=30%',
          },
          duration: 0.8,
          stagger: 0.025,
          ease: custom,
          y: '200%',
        })
      })

    break

    case 'projects':

      const projects = qs('.projects')
      const workChars = qsa('.projects .char-0')
      const bg = qs('.projects .projects-bg')

      if(!projects) return

      // gsap.from(bg, {
      //   scrollTrigger: {
      //     trigger: '.projects', 
      //     start: 'top center+=35%',
      //   },
      //   duration: 1,
      //   ease: custom,
      //   scaleY: 0,
      // })


      gsap.from(workChars, {
        scrollTrigger: {
          trigger: '.projects', 
          start: 'top center+=30%',
        },
        duration: 0.8,
        stagger: 0.025,
        ease: custom,
        y: '120%',
      })

      const projectTL = gsap.timeline({
        scrollTrigger: {
          trigger: '.projects',
          start: 'top top+=25%',
         // markers: true,
          
        },
      })
      
      projectTL.from('.projects .box-content', {duration: 1, x: '-101%', ease: custom })
      projectTL.from('.projects .box-inner', {duration: 1, x: '101%', ease: custom }, '-=1')
      projectTL.from('.projects .box-items', {duration: 1, x: '-101%', ease: custom },'-=0.6')
      projectTL.from('.projects .items-inner', {duration: 1, x: '101%', ease: custom }, '-=1')

    break


    case 'about':

    const services = qs('.about .about-services')
    const stats = qs('.about .about-stats')
    const aboutSection = qs('.about') 
    let about

    if(!aboutSection) return
    
      mm.add("(min-width: 750px)", ()=> {
        about = gsap.timeline({
          scrollTrigger: {
            trigger: '.about', 
            start: 'top center+=30%',
          }
        })
      })

      mm.add("(max-width: 749px)", ()=> {
        about = gsap.timeline({
          scrollTrigger: {
            trigger: '.about', 
            start: 'top center+=80%',
          }
        })
      })

      const list = qsa('.about .item-list')
      about.from('.about .about-inner', {duration: 0.8, x: '-101%', ease: custom })
      about.from('.about .about-crop', {duration: 0.8, x: '101%', ease: custom }, '-=0.8')
      about.to('.about .about-bg', {duration: 1, scaleY: 0, ease: custom }, '-=0.8')
      about.from('.about .about-description .line-0', { duration: 1, y: 30, autoAlpha: 0, stagger: 0.045, ease: custom }, '-=0.8')
      about.from('.about .about-title', {duration: 0.8, autoAlpha: 0, y: '100%', ease:custom}, '-=0.9')
      
      if(services) {
        about.from('.about .service-title .line-0', {duration: 0.8, y: '150%', ease:custom}, '-=1')
        list.forEach((item)=> {
          const li = qsa('li', item)
          about.from(li, {duration: 0.8, autoAlpha: 0, y: 20,  stagger: 0.05, ease:custom}, '-=0.8')
        })
        about.from('.about .icons-inner', {duration: 1, y: '-101%', ease: custom }, '-=1')
        about.from('.about .icons-crop', {duration: 1, y: '101%', ease: custom }, '-=1')
      }

      if(stats) {
        about.from('.about .stats-subitem .-p5', {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.12, ease:custom}, '-=1')
        about.from('.about .stats-subitem .-p', {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.12, ease:custom}, '-=0.8')
        about.from('.about .about-letter .letter-item', {duration: 2.2, autoAlpha: 0, ease:custom}, '-=0.7')
        about.from('.about .about-block.-bottom', {duration: 1, scaleY: 0, ease: custom }, '-=1.8')
      }

    break

    case 'contact':

      const contactSection = qs('.contact')

      if(!contactSection) return

      const contact = gsap.timeline({
        scrollTrigger: {
          trigger: '.contact', 
          start: 'top center+=20%',
        }
      })

      contact.from('.contact .header-inner', {duration: 1, x: '-101%', ease: custom})
      contact.from('.contact .header-crop', {duration: 1, x: '101%', ease: custom }, '-=1')
      contact.from('.contact .form-group', { duration: 0.8, y: '40%', autoAlpha: 0, stagger: 0.1, ease: custom}, '-=0.5')

    break

    case 'recognition':

    const recognitionSection = qs('.recognition')

    if(!recognitionSection) return

      const recognition = gsap.timeline({
        scrollTrigger: {
          trigger: '.recognition', 
          start: 'top center+=20%',
        }
      })

      recognition.from('.recognition-header', {duration: 1, x: '-101%', ease: custom})
      recognition.from('.recognition-crop', {duration: 1, x: '101%', ease: custom }, '-=1')
      recognition.from('.recognition .recognition-item', { duration: 0.8, y: '40%', autoAlpha: 0, stagger: 0.1, ease: custom}, '-=0.5')

    break

    case 'process':

    const procesItems = qsa('.process .process-item')

    if(!procesItems[0]) return

    procesItems.forEach((item)=> {
      const nr = qs('.process-nr', item)
      const title = qsa('.list-title .char-0', item)
      const latin = qsa('.list-latin', item)
      const txt = qsa('.list-text', item)

      const itemList = gsap.timeline({
        scrollTrigger: {
          trigger: item, 
          start: 'top center+=30%',
        }
      })

      itemList.from(nr, {duration: 0.5, autoAlpha: 0, y: 20})
      itemList.from(title, {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.03, ease: custom}, '-=0.4')
      itemList.from(latin, {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.08, ease: custom},'-=0.8')
      itemList.from(txt, {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.08, ease: custom},'-=1')

    })

      const process = gsap.timeline({
        scrollTrigger: {
          trigger: '.process', 
          start: 'top center+=30%',
        }
      })

      process.to('.process .step-line', { duration: 0.3, scaleX: 0, stagger: 0.012, ease: custom})
    

    break

    case 'featured':

      const featuredSection = qsa('.featured')

      if(!featuredSection[0]) return

      featuredSection.forEach( (item)=> {
        const anim = item.dataset.anim

        if(anim !== 's') return

        const featured =  gsap.timeline({
          scrollTrigger: {
            trigger: item, 
            start: 'top center',
          }
        })

        let char = qsa('.char-1', item)
        let title = qs('.-p1', item)
        let image = qs('.featured-image', item)
        let crop = qs('.image-crop', item)
        let box = qs('.box-inner', item)
        let boxcrop = qs('.box-crop', item)
        let items = qs('.items-inner', item)
        let itemscrop = qs('.items-crop', item)

        featured.from(char, { duration: 1.2, y: '101%', stagger: 0.05,  ease: custom })
        featured.from(title, {duration: 1, y: '101%', ease: custom }, '-=1.3')
        featured.from(image,{ duration: 1, y: '101%', ease: custom}, '-=1.2')
        featured.from(crop,{ duration: 1, y: '-101%', ease: custom}, '-=1.2')

        const featuredScroll = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: 'bottom bottom',
          },
        })

        featuredScroll.from(box, {duration: 1, x: '-101%', ease: custom })
        featuredScroll.from(boxcrop, {duration: 1, x: '101%', ease: custom }, '-=1')
        featuredScroll.from(items, {duration: 1, x: '-101%', ease: custom }, '-=0.8')
        featuredScroll.from(itemscrop, {duration: 1, x: '101%', ease: custom }, '-=1')

      })

    break

    case 'galleryBranding':

    const brandingSection = qs('.galleryBranding')
    const brandingItems = qsa('.galleryBranding-item')

    if(!brandingSection) return

    brandingItems.forEach((item, i)=> {

      const img  = qs('img', item)

      const branding = gsap.timeline({
        scrollTrigger: {
          trigger: item, 
          start: 'top center+=40%',
          scrub: true,
        }
      })

      branding.from(img, {duration: 1, scale: 1.1, delay: i * 0.05 , ease: custom})

    })

    break

    case 'footer':

      const projectChars = qsa('.footer-title .char-0')
      const footerSection = qs('.footer')

      if(!footerSection) return

      const footerTitle = gsap.timeline({
        scrollTrigger: {
          trigger: '.footer', 
          start: 'top center+=25%',
        }
      })

      footerTitle.from(projectChars, {duration: 0.8, y: '120%', stagger: 0.025, ease: custom})
      footerTitle.fromTo('.footer .stroke', {strokeDashoffset: 0}, {duration: 1.2, strokeDashoffset: 1000,  ease: custom }, '-=1.4')

      const footer = gsap.timeline({
        scrollTrigger: {
          trigger: '.footer',
          start: 'top center+=20%',   
        },
      })

      footer.from('.footer .social-item', {duration: 1, x: '-101%', stagger: 0.025, ease: custom }, 0.5)
      footer.from('.footer .social-inner', {duration: 1, x: '101%', stagger: 0.025, ease: custom }, 0.5)
      footer.from('.footer-contact', { duration: 0.8, scale: 0, ease: custom}, '-=0.8')
      footer.from('.footer-reachout', { duration: 1, autoAlpha: 0}, '-=0.6')
      footer.from('.footer-locations', { duration: 1, autoAlpha: 0}, '-=1')

    break
  
  }
}
