import store from '../store'
import Default from './Default'
import { qs } from '../utils'
import gsap from 'gsap'

export default class Page extends Default {
  constructor(opt = {}) {
    super(opt)
    this.slug = 'page'
  }

  onEnter() {
    super.onEnter()

  }

  onEnterCompleted() {
    super.onEnterCompleted()

    
  }

  getDom() {
    super.getDom()

    Object.assign(this.dom, {
      workBg: qs('.work-bg'),
      isWork: qs('.is-work')
    })

    
    const { isWork, workBg } = this.dom

    if(isWork) gsap.to(workBg, { duration: 1, autoAlpha: 1})
  }

  on() {
    super.on()
  }

  off() {
    super.off()
  }

  onLeave() {
    super.onLeave()

    const { isWork, workBg } = this.dom

   if(isWork) gsap.to(workBg, { duration: 1, autoAlpha: 0})

  }

  onLeaveCompleted() {
    super.onLeaveCompleted()
  }
}
