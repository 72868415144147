import store from './store'
import { Core } from './core'
import { qs, Sniffer, Grid, getViewport, getWindowSizes } from './utils'

import lazySizes from 'lazysizes'

export default class App {
  constructor() {

    console.log(
      `%c${store.title} \nMade with ❤️ by ${store.author.name} \n↳ ${store.author.link}`,
      'color: #342f2f',
    )
    this.init()
  }

  settings() {
    // Initial Settings
    store.sizes.vw = getViewport().width
    store.sizes.vh = getViewport().height
    store.breakpoints = getWindowSizes()
    store.sniff = Sniffer.sniff

    // Global
    store.header = qs('.header')
    store.msgs.rotate = qs('.-rotate')

    // LazySizes clases
    lazySizes.cfg.lazyClass = 'lazy'
    lazySizes.cfg.loadedClass = 'loaded'
  }

  core() {
    // Initialize core components
    this.appCore = new Core()
  }

  grid() {
    // Initialize Grid cmd+g to activate
    new Grid({
      background: '#a25b4c',
      desktop: {
        columns: 18,
      },
      mobile: {
        columns: 4,
        gutter: 19,
        size: 390,
      },
    })
  }

  init() {
    this.settings()
    this.core()
    this.grid()
  }
}
