import store from '../store'
import { qsa } from '../utils'




let sources = [
    {
      name: 'logo',
      type: 'gltfModel',
      path: ['/models/spinner0.glb'],
    },
    {
      name: 'logoTexture1',
      type: 'texture',
      path: ['/models/logo-matcap.jpg'],
    },
    {
      name: 'logoTexture2',
      type: 'texture',
      path: ['/models/circle-matcap.jpg'],
    }

  ]  



export default sources
