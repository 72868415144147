import * as THREE from 'three'
import store from '../store'
import { Core, Emitter } from '../core'
import WebGlManager from './WebGlManager'
import Resources from './Resources'
import DomGl from './DomGl'
import SliderGL from './SliderGL'
import HeroGl from './HeroGl'
import GalleryGL from './GalleryGL'
import { qs, qsa } from '../utils'

export default class World {
  constructor() {

    this.manager = new WebGlManager()
    this.core = new Core()
    this.resources = this.manager.resources
    this.taxi = this.core.taxi
    this.elements = []
    this.projects = []
    this.studio = []
    this.slider = []
    this.backgrounds = []
    this.isResizing = false
    this.animate = true
    this.state = {
      addHero: false,
      addProjects: false,
      addGallery: false
    }


    this.displacementMap = {
      name: 'displacementMap',
      type: 'texture',
      path: '/images/texture.jpg',
    }

    this.init()
  }

  addHero() {
    const hero = qs('[data-hero]')
    const { addHero } = this.state
    if(addHero || !hero) return
    this.state.addHero = true
    this.hero =  new HeroGl()
    this.hero.init(hero)
    this.elements.push(this.hero)
  }

  removeHero() {
    if(!this.hero) return
    this.hero.animateOut()
    //this.state.addHero = false
  }

  addProjects() {
    const { sniff } = store
    const { addProjects } = this.state
    const {currentLocation } = this.taxi
    const gallery = qs('.galleryProjects') || qs('.projects')
    const home = qs('.projects')
    const show = home ? true : false

    if(!gallery) return

    const projects = qsa('[data-plane]')
    const background = qs('.bg-canvas')
   
   
    if(addProjects || !projects[0] || sniff.isDevice) return 
   
    const source = [...projects].map((el, i) => {
      return {
        name: `project${i}`,
        type: 'texture',
        path: el.dataset.jpg,
      }
    })

    source.push(this.displacementMap)
    this.state.addProjects = true
    this.state.addBg = true


    const p = new Resources(source).on('ready', ()=> {

      const keys = Object.keys(p.items)
      const obj = {
        color: currentLocation.pathname == '/' ? 'white' : 'black'
      }  

      for (const key of keys) {
        this.resources.items[key] = p.items[key] 
      }

      this.bg = new DomGl(obj)
      this.bg.name = 'background'
      this.bg.init(background)
      this.backgrounds.push(this.bg)

      projects.forEach((el, i) => {
        const b = new DomGl(i, {show: show})
        b.init(el)
        this.projects.push(b)
      })
    })
  }

  removeProjects() {
    if(this.projects.length == 0 ) return
    this.projects.forEach((el) => el.animateOut())
    this.bg && this.bg.animateOut()
    this.projects = []
    this.backgrounds = []
    this.state.addProjects = false 
  }

  addSlider() {
    const { sniff } = store
    const slider = qs('.work-slider')
    const slides = qsa('[data-plane]')

    if(!slider || !slides[0] || sniff.isDevice) return 


    const source = [...slides].map((el, i) => {
      return {
        name: `slide${i}`,
        type: 'texture',
        path: el.dataset.jpg,
      }
    })

    source.push(this.displacementMap)


    const p = new Resources(source).on('ready', ()=> {

        const keys = Object.keys(p.items)
     
        for (const key of keys) {
          this.resources.items[key] = p.items[key] 
        }
  
        slides.forEach((el, i) => {
          const b = new SliderGL(i, {total: slides.length})
          b.init(el)
          this.slider.push(b)
        })

        Emitter.emit('textureReady')
     
    })

  }

  removeSlider() {
    if(this.slider.length == 0 ) return
    this.slider.forEach((el) => el.animateOut())
    this.slider = []
    this.state.addSlider = false
  }

  addGallery() {
    const { sniff } = store
    const { addGallery } = this.state
    const gallery = qs('[data-gallery]')


    if(addGallery || !gallery || sniff.isDevice) return

    const images = qsa('img', gallery)

    const source = images.map((el, i) => {
      return {
        name: `gallery${i}`,
        type: 'texture',
        path: el.dataset.src,
      }
    })
  
    source.push(this.displacementMap)
    
    this.state.addGallery = true

    const p = new Resources(source).on('ready', ()=> {

      const keys = Object.keys(p.items)
      for (const key of keys) {
       
       if( key.includes('gallery')) {
          p.items[key].name = 'gallery'
       }

        this.resources.items[key] = p.items[key] 
      }

      this.gallery = new GalleryGL()
      this.gallery.init(gallery)
      this.studio.push(this.gallery)
    })

  }

  removeGallery() {
    if(this.studio.length == 0 ) return
    this.studio.forEach((el) => el.animateOut())
    this.studio = []
    this.state.addGallery = false
  }

  update(obj) {

    const {current, last} = obj

    if(this.isResizing) return

    this.elements.forEach((el) => {
      el.updatePosition(current, last)
      el.update(obj)
    })

    this.projects.forEach((el) => {
      el.updatePosition(current, last)
      el.update(obj)
    })

    this.studio.forEach((el) => {
      el.updatePosition(current, last)
      el.update(obj)
    })

    this.slider.forEach((el) => {
      el.updatePosition(current, last)
      el.update(obj)
    })

    this.backgrounds.forEach((el) => {
      el.updatePosition(current, last)
      el.update(obj)
    })
  }

  resize() {
    this.isResizing = true

    this.elements.forEach((el) => el.resize())
    this.projects.forEach((el) => el.resize())
    this.backgrounds.forEach((el) => el.resize())
    this.slider.forEach((el) => el.resize())
    this.studio.forEach((el) => el.resize())
    this.isResizing = false
  }

  destroy() {}

  onEnter() {
    this.addHero()
    this.addProjects()
    this.addGallery()
    this.addSlider()
  }

  onLeave() {
    this.removeHero()
    this.removeProjects()
    this.removeGallery()
    this.removeSlider()
  }

  init() {}
}
