import store from "../store"
import { bounds } from "../utils"
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import gsap from "gsap"

export default class Stick {
  constructor(obj={}) {

    this.isSmooth = store.flags.smooth

    this.dom = {
      container: obj.container,
      sticky: obj.sticky,
      items:obj.items,     
    }

    this.settings =  {
      fullWidth: 0,
      fullHeight: 0,
      width: 0,
      posX: 0,
      posY: 0,
      enter: 0,
      totalItems: 0,
    }

    this.state = {
      fixed: false,
    }

    this.init()

  }

  setup() {

    const {  items, container} = this.dom
    const {sizes} = store
    const rect = bounds(items[0])
    const fullHeight = bounds(container).height
    const totalItems = items.length 

    this.settings.totalItems = totalItems
    this.settings.width = rect.width
    this.settings.fullWidth = (totalItems * rect.width ) - rect.width
    this.settings.fullHeight = fullHeight - sizes.vh

  }

  stick() {

    const { container } = this.dom
    
    ScrollTrigger.create({
      trigger: container,
      start: 'top +=50%',
      end: 'top top',
      scrub: true,
      onEnter: () => {
        //this.beforeEnter()
      },
      onUpdate: (self)=> {
        this.beforeEnter(self)
      }
    })

    ScrollTrigger.create({
      trigger: container,
      start: 'top top',
      end: 'bottom bottom',
      scrub: true,
      onEnter: () => {
        this.onEnter()
      },
      onEnterBack: () => {
        this.onEnterBack()
      },
      onLeaveBack: () => {
        this.onLeaveBack()
      },
      onLeave: () => {
        this.onLeave()
      },
      onUpdate: (self)=> {
       this.onUpdate(self)
      },
      onRefresh: () => {
       this.onRefresh()
      }
    })
  }

  beforeEnter (self) {
    this.settings.enter = self.progress 
  }

  onEnter () {
    const { sticky } = this.dom
    this.state.fixed = true
    if(!this.isSmooth) {
      sticky.classList.add('-fixed')
      gsap.set(sticky, { marginTop: 0 })
    }
  }

  onEnterBack () {
    const { sticky } = this.dom
    this.state.fixed = true

    if(!this.isSmooth) {
      sticky.classList.add('-fixed')
      gsap.set(sticky, { marginTop: 0 })
    }
  }

  onLeave () {
    const { sticky } = this.dom
    const { totalItems } = this.settings

    this.state.fixed = false
    if(!this.isSmooth) {
      sticky.classList.remove('-fixed')
      gsap.set(sticky, { marginTop: `${(totalItems - 1) * 100}vh` })
    }
  }

  onLeaveBack () {
    const { sticky } = this.dom

    this.state.fixed = false
    if(!this.isSmooth) {
      sticky.classList.remove('-fixed')
      gsap.set(sticky, { marginTop: 0 })
    }
  }

  onUpdate (self) {
    const { sticky } = this.dom
    const { fullWidth, fullHeight } = this.settings

    if(this.isSmooth) {
      this.settings.posY = self.progress * fullHeight 
      this.settings.posX = self.progress * fullWidth 
      gsap.set(sticky, { y: this.settings.posY  })
    } else {
      this.settings.posX = self.progress * fullWidth 

    }
  }

  onRefresh () {}

  destroy() {}

  resize = ()=> {
    this.setup()
    //ScrollTrigger.refresh()
  }


  init() {  
    this.setup()
    this.stick()
  }
}