import store from '../store'
import * as Taxi from '@unseenco/taxi'
import { Page } from '../renders'
import { Fade } from '../transitions'
import { CustomEase } from '../vendor/CustomEase'
import {
  Emitter,
  GlobalRaf,
  GlobalVRaf,
  VScroll,
  Mouse,
  GlobalResize,
  AssetLoader
} from './'
import WebGlManager from '../webgl/WebGlManager'
import { qs } from '../utils'
import gsap from 'gsap'
let singleton = null

export default class Core {
  constructor() {
    if (!singleton) {
      singleton = this
    } else {
      return singleton
    }

    gsap.registerPlugin(CustomEase)


    this.customEase = CustomEase.create('custom', 'M0,0 C0.1,0 0.148,0.034 0.182,0.136 0.216,0.238 0.239,0.685 0.448,0.886 0.55,0.984 0.704,1 1,1')

    this.ready = false

    // Store
    this.store = store
     // Taxi
    this.taxi = new Taxi.Core({
      links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore])',

      renderers: {
        default: Page,
        page: Page,
      },

      transitions: {
        default: Fade,
      },
    })

    this.store.taxi = this.taxi

    // Global Request Animation Frame
    this.raf = new GlobalVRaf() // When using Custom Scroll
    //this.raf = new GlobalRaf() // When using Lenis
    // Global Scroll
    this.scroll = new VScroll({ smooth: !store.sniff.isDevice }) // Custom Smooth
    //this.scroll = new Scroll() // Lenis
    // Global Mouse
    this.mouse = new Mouse()
    // Global Resize
    this.resize = new GlobalResize()
    // Asset Loader (Needs a data file data.yml)
    this.loader = new AssetLoader()
    
    this.webgl = new WebGlManager({
      el: qs('.dom-gl')
    })
    this.init()
  }

  init() {
    // Initialize core methods
    this.raf.on()
    this.scroll.on()
    this.mouse.on()

    this.loader.on().then((responses) => {
    
      this.ready = true

      if(this.webgl) {
        //Emitter.emit('loaded')
       
         this.webgl.resources.on('ready', ()=> {

          gsap.set('.header', { y: '0%'})
          gsap.from('.header a', {duration: 0.8, y : '-200px', stagger: 0.08, ease: this.customEase})
          this.webgl.createWorld()
          this.webgl.on()
           Emitter.emit('loaded')
        })
      } else {
        //Emitter.emit('loaded')
      }
     
    })
  }
}
