import { Core, Emitter } from '../core'
import { qsa, getid, bounds } from '../utils'
import gsap from 'gsap'

export default class Menu {
  constructor(obj = {}) {

    this.core = new Core()

    const desktopMenu = obj.deskMenu
    const mobileMenu = obj.mobMenu
    const footer = obj.footer
    const url = obj.url

    this.dom = {
      open: obj.open,
      close: obj.close,
      desktop: {
        el: desktopMenu,
        links: desktopMenu ? qsa('a', desktopMenu) : null,
      },
      mobile: {
        el: mobileMenu,
        links: mobileMenu ? qsa('a', mobileMenu) : null,
      },
      footer: {
        links: footer ? qsa('a', footer) : null,
      },
      url: url,
    }

    this.hashes = qsa('a[href^="#"]')


    this.init()
  }

  tick = ({current}) => {
    this.current = current
  }

  on() {
    const { open, close, mobile } = this.dom
    open && open.addEventListener('click', this.openMenu)
    close && close.addEventListener('click', this.closeMenu)
    mobile.links[0] && mobile.links.forEach((link)=>  link.addEventListener('click', this.closeMenu))

    this.hashes.forEach(anchor => {
      anchor.addEventListener('click', this.scrollTo)
    })  

    Emitter.on('tick', this.tick)

  }

  off() {

    const { open, close } = this.dom
    open && open.removeEventListener('click', this.openMenu)
    close && close.removeEventListener('click', this.closeMenu)

    this.hashes.forEach(anchor => {
      anchor.removeEventListener('click', this.scrollTo)
    })

    Emitter.off('tick', this.tick)

  }


  scrollTo = (e)=> {
   
    e.preventDefault()
    const target = e.target
    const href= target.getAttribute('href')
    const id = href.replace(/#/g, '')
    const section = id ? getid(id) : null
   
    if(section) {

      this.getScrollTop(section)

    } else {

      this.core.taxi.navigateTo('/', 'default').then(() => {
        const section = getid('contact')
        this.getScrollTop(section)
      })

    }
  }

  getScrollTop(section) {

    const top =  bounds(section).top
    const matrix = window.getComputedStyle(section).getPropertyValue('transform')
    const matrixValues = matrix.split('(')[1] ? matrix.split('(')[1].split(')')[0].split(',') : 0
    const translateY = matrixValues ? parseInt(matrixValues[5]) : 0
    const windowTop = window.pageYOffset
    const topBoundWithTranslation = top + (translateY * -1) + windowTop

    this.core.raf.scrollTo(topBoundWithTranslation)

  }

  updateMenu(url) {
    if (!url) return

    const { desktop, mobile, footer } = this.dom
    const location = url

    desktop.links &&
      desktop.links.forEach((link) => {
        link.parentNode.classList.remove('-active')
        if (link.href === location) {
          link.parentNode.classList.add('-active')
        }
      })

    mobile.links &&
      mobile.links.forEach((link) => {
        link.parentNode.classList.remove('-active')
        if (link.href === location) {
          link.parentNode.classList.add('-active')
        }
      })

    footer.links &&
      footer.links.forEach((link) => {
        link.parentNode.classList.remove('-active')
        if (link.href === location) {
          link.parentNode.classList.add('-active')
        }
      })
  }

  openMenu = () => {
    const { mobile } = this.dom
    const menu = mobile.el
    const tl = gsap.timeline()
  
    tl.clear()

    gsap.set(menu, { autoAlpha: 1 })
    tl.fromTo(
      menu,
      { y: '101%' },
      { duration: 0.5, y: '0%', ease: 'power3.inOut' },
    )

    tl.from('.mobile .menu-item', {
      duration: 0.8,
      autoAlpha: 0,
      stagger: 0.08,
      y: 20,
    }, '-=0.2')

    tl.from('.mobile .social-item', {
      duration: 0.8,
      autoAlpha: 0,
      stagger: 0.08,
      y: 20,
    }, '-=0.6')

    tl.from('.mobile .line-0', {
      duration: 0.8,
      autoAlpha: 0,
      stagger: 0.08,
      y: 20,
    }, '-=0.8')
  }

  closeMenu = () => {
    const { mobile } = this.dom
    const menu = mobile.el

    gsap.fromTo(
      menu,
      { y: '0%' },
      {
        duration: 0.5,
        y: '101%',
        ease: 'power3.inOut',
        onComplete: () => {
          gsap.set(menu, { autoAlpha: 0 })
        },
      },
    )
  }

  destroy() {
    this.off()
  }

  init() {
    this.updateMenu(this.dom.url)
    this.on()
  }
}
