import { animateIn, animateOut, animateScroll } from '../animations'
import { CustomEase } from '../vendor/CustomEase'
import gsap from "gsap"
import { qsa } from '../utils'

export default class AnimationManager {
  constructor() {
    
  
    const animations = qsa('[data-anim]')

    this.animIn = []
    this.animOut = []
    this.animScroll = []


    animations.forEach((anim)=> {
      const ids = anim.dataset.anim.split(',')
      ids.forEach((id) => {
        
        if(id == 'i') this.animIn.push(anim)
        if(id == 'o') this.animOut.push(anim)
        if(id == 's') this.animScroll.push(anim)
      
      })
    })

  }

  in() {
    if (!this.animIn[0]) return

    this.animIn.forEach((anim) => {
      animateIn(anim.id)
    })
  }

  out() {
    if (!this.animOut[0]) return

    this.animOut.forEach((anim) => {
      animateOut(anim.id)
    })
  }

  onScroll() {
    if (!this.animScroll[0]) return

    this.animScroll.forEach((anim) => {
      animateScroll(anim.id)
    })
  }
}
