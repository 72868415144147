import store from '../store'
import gsap from 'gsap'
import { Core, Emitter } from '../core'
import { qs, qsa } from '../utils'

export const animateIn = (animation) => {


  const { breakpoints } = store
  const core = new Core()
  const custom =  core.customEase
  const world = core.webgl.world ? core.webgl.world : null
  const studio = qs('.is-studio')
  const home = qs('.is-home')
  const privacy = qs('.is-privacy')



  const corners = 
  {
    LR: breakpoints.L ? '42%' : '22%' ,
    TB: breakpoints.L ? '32%' : '16%' ,  
  }

  const borders = 
  {
    left: breakpoints.L ? '-15%' : '-35%' ,
    right: breakpoints.L ? '15%' : '35%' ,
    top: breakpoints.L ? '-35%' : '-45%' ,
    bottom: breakpoints.L ? '35%' : '45%' ,
  }

  switch (animation) 
  {
    case 'hero':

      const hero = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

     gsap.set('.page', {autoAlpha: 1})
     
     // Home page animation

     if(home)  {  
        hero.from('.banner-corner.left-top', {duration: 0.8, top: corners.TB, left: corners.LR, delay: 0.2})
        hero.from('.banner-corner.right-top', {duration: 0.8, top: corners.TB, right: corners.LR }, '-=0.8')
        hero.from('.banner-corner.left-bottom', {duration: 0.8, bottom: corners.TB, left: corners.LR}, '-=0.8')
        hero.from('.banner-corner.right-bottom', {duration: 0.8, bottom: corners.TB, right: corners.LR}, '-=0.8')

        hero.fromTo('.border.top', { y: borders.top}, {duration: 0.8, y: '-120%'}, '-=0.8')
        hero.fromTo('.border.right', { x: borders.right}, {duration: 0.8, x: '110%'}, '-=0.8')
        hero.fromTo('.border.bottom',{y: borders.bottom }, {duration: 0.8, y: '120%'}, '-=0.8')
        hero.fromTo('.border.left', {  x: borders.left}, {duration: 0.8, x: '-110%'}, '-=0.8')
       
        hero.from('.hero-title .char-0', { duration: 0.8, y: '101%', stagger: 0.025, ease: custom }, '-=0.8')
        hero.from('.hero-description .word-1', { duration: 0.8, y: '101%', stagger: 0.05, ease: custom }, '-=1.2')

        hero.from('.hero-established', {duration: 1, x: '-101%', ease: custom }, '-=0.8')
        hero.from('.established-inner', {duration: 1, x: '101%', ease: custom }, '-=1')

        hero.from('.scroll-inner', {duration: 1, x: '-101%', ease: custom }, '-=0.6')
        hero.from('.scroll-crop', {duration: 1, x: '101%', ease: custom, onComplete: ()=> {

          const tl = gsap.timeline({
          repeat: -1
          })

          tl.to('.scroll-icon', {duration: 0.5, y: '150%', delay: 0.5} )
          tl.set('.scroll-icon', {y: '-150%'})
          tl.to('.scroll-icon', {duration: 0.5, y: '0%'} )


        } }, '-=1')

      }

      if(home && world.hero) {

        const glHero = world.hero
        const { squiggle, uniforms, circle, container } = glHero


        glHero.animateIn()

        hero.to( uniforms.uAlpha, { duration: 1, value: 1 }, '-=1.8' )
        hero.from( squiggle.scale, { duration: 0.5, x: 0.3, y: 0.3, z:0.3 }, '-=1.6' )
        hero.from(container.position, { duration: 0.5, y: -0.05,  }, '-=1.6' )
        hero.from( circle.scale, { duration: 0.8, x: 0, y: 0, z:0 }, '-=1.6' )
        hero.from( circle.rotation, { duration: 0.8, x: -1.6, y: 0, z:0 }, '-=1.5' )
      }

      if(studio){      
        hero.from('.hero .char-0', { duration: 1.2, y: '101%', stagger: 0.05,  ease: custom })
        hero.from('.hero .-p', { duration: 1, y: 20,  autoAlpha:0, stagger: 0.08, ease: custom }, '-=0.4')
        hero.from('.hero .image-crop',{ duration: 0.8, y: '101%', ease: custom}, '-=0.9')
        hero.from('.hero .-cover',{ duration: 0.8, y: '-101%', ease: custom}, '-=0.9')
      }

      if(privacy) {
        hero.to('.header', { duration: 0.5, y: '0%'})
        hero.from('.heroPrivacy .char-1', { duration: 1.2, y: '101%', stagger: 0.05, ease: custom }, '-=0.4')
        hero.from('.heroPrivacy .word-1', { duration: .6, y: '101%', stagger: 0.01, ease: custom }, '-=0.4')
        hero.from('.heroPrivacy-date', { duration: .6, y: '101%', autoAlpha: 0, ease: custom }, '-=1')
      }



      hero.play()

      break

      case 'featured':

        const featuredSection = qsa('.featured')

        if(!featuredSection[0]) return


        featuredSection.forEach( (item)=> {
          const anim = item.dataset.anim
  
          if(anim !== 'i') return

          const featured = gsap.timeline({
            paused: true,
            onComplete: () => {
              Emitter.emit('animateIn:ended')
            },
          })
  
          gsap.set('.page', {autoAlpha: 1})

          let char = qsa('.char-1', item)
          let title = qs('.-p1', item)
          let image = qs('.featured-image', item)
          let crop = qs('.image-crop', item)
          let box = qs('.box-inner', item)
          let boxcrop = qs('.box-crop', item)
          let items = qs('.items-inner', item)
          let itemscrop = qs('.items-crop', item)

          featured.from(char, { duration: 1.2, y: '101%', stagger: 0.05,  ease: custom })
          featured.from(title, {duration: 1, y: '101%', ease: custom }, '-=1.3')
          featured.from(image,{ duration: 1, y: '101%', ease: custom}, '-=1.2')
          featured.from(crop,{ duration: 1, y: '-101%', ease: custom}, '-=1.2')
          featured.to('.header', { duration: 0.5, y: '0%'})
          featured.play()

          const featuredScroll = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'bottom bottom',
            },
          })

          featuredScroll.from(box, {duration: 1, x: '-101%', ease: custom })
          featuredScroll.from(boxcrop, {duration: 1, x: '101%', ease: custom }, '-=1')
          featuredScroll.from(items, {duration: 1, x: '-101%', ease: custom }, '-=0.8')
          featuredScroll.from(itemscrop, {duration: 1, x: '101%', ease: custom }, '-=1')
        })

      break

      case 'work' :
  
      const slideLines = qsa('.slider-line')

      const work = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      gsap.set('.page', {autoAlpha: 1})
      gsap.set('.slider-bg', { clearProps: 'all'})
      gsap.set('.bg-crop', { clearProps: 'all'})
      work.from('.slider-bg', { duration: 0.8, autoAlpha: 0, delay: 0.15, ease: custom })
      work.to('.header', { duration: 0.5, y: '0%'}, '-=0.5')
      work.from(slideLines, {duration: 0.5, scaleX: 0, stagger: 0.05, onComplete: (el)=> {
        gsap.set(slideLines, {clearProps: 'all'})
        
        slideLines.forEach((el)=> el.classList.add('line-icon'))

      }}, '-=0.2')
      work.from('.slider-nr', {duration: 0.5, autoAlpha: 0}, '-=0.4')
      work.from('.grid-view', {duration: 0.5, autoAlpha: 0}, '-=0.3')
      work.play()

    
      break

  }
}
