import store from "../store"
import { Core, Emitter } from "../core"
import InfiniteLoop from './InfiniteLoop';
import { CustomEase } from '../vendor/CustomEase'
import { qs, bounds, lerp, clamp } from "../utils"
import WebGlManager from "../webgl/WebGlManager"
import debounce from 'lodash/debounce'
import gsap from 'gsap';

export default class InfiniteWorks extends InfiniteLoop {
      
    constructor(obj={}) {
      super(obj)
      
      this.manager = new WebGlManager()
      this.els = this.manager.world.slider
      this.dom.bgs = obj.bgs
      this.dom.nav = obj.nav
      this.dom.nr = obj.nr     
      this.settings.scrollOffset = 0
      this.settings.scrollB = 0


      this.init()
    }

    setup() {

      super.setup()      
      const { bgs } = this.dom

      if(!bgs[0]) return

      const bg = qs('.work-bg')
      const totalItems = bgs.length - 1
      const bgRect = bounds(bgs[0])
      const bgFullHeight = bgRect.height * totalItems 
      const slideHeight = bgRect.height * bgs.length
      const { height } = this.settings
     
      this.settings.totalBgs = bgs.length
      this.settings.bgHeight = bgRect.height
      this.settings.bgFullHeight = bgFullHeight 
      this.settings.diff =  bgRect.height / height
      this.settings.offset = 0
      this.settings.scroll = 0
      this.settings.slideHeight = slideHeight
      this.index = 0


    }


    getOffset = ()=>  {

      const { height } = this.settings
      const offset = (store.sizes.vh * 0.5) - (height * 0.5) 

      this.settings.offset = offset
      this.settings.scroll = offset
      this.snap()
    }

    run() {
      super.run()

      const {bgs} = this.dom
      const { bgHeight, bgFullHeight, slideHeight } = this.settings

      this.settings.scrollOffset = lerp( this.settings.scrollOffset, this.settings.scrollB, 0.05)

      bgs.forEach((el, i) => {
        let y = i * -bgHeight + (this.settings.scrollOffset * -1)
        y = gsap.utils.wrap(-bgHeight, bgFullHeight, (y))
        gsap.set(el, { y: `${y}px` })
      })
      

      this.els.forEach((el, i) => {
        let y =  (this.settings.scrollOffset )
        gsap.set(el.state, { posY: parseInt(y) })
      })

    }


    touchmove(e) {
      super.touchmove(e)
      const {diff} = this.settings
      
      //this.settings.touchY = e.clientY || e.touches[0].clientY
      this.settings.scrollB -= ((this.settings.touchY - this.settings.start) * .1) * diff
     
    }

    move(wheel) {
      super.move(wheel)

      const {diff} = this.settings
      this.settings.scrollB -= wheel.pixelY * diff
    }
    
    down(key) {
      const { height, bgHeight } = this.settings
  
     if(key === 40 || key === 39 || key === 32) {
      this.settings.scroll -= height 
      this.settings.scrollB -= bgHeight 
      this.snap()
     }
  
     if(key === 38 || key === 37) {
      this.settings.scroll += height 
      this.settings.scrollB += bgHeight 
      this.snap()
     }
    }
  

    snap() {
      super.snap()

      const tl = gsap.timeline()
      tl.clear()

      const {bgs, nav, items, nr} = this.dom
      const {scroll, height, bgHeight, offset } = this.settings
      
      let index, current, box, boxCrop, tags, tagsCrop

      if(this.settings.scroll < 0) {
        this.settings.scroll = -(this.index  * height ) + offset
      } else {
        this.settings.scroll = (this.index  * height ) + offset
      }

      if (this.settings.scrollB < 0) {
        this.settings.scrollB = -(this.index  * bgHeight ) 
      } else {
        this.settings.scrollB = (this.index  * bgHeight ) 
      }

      if(scroll < 0) {
        index = this.index % bgs.length
      } else {
        index = (bgs.length - (this.index % bgs.length)) % bgs.length
      }
      
      items.forEach((item, i) => { item.classList.remove('-active') })
      bgs.forEach((item, i) => { item.classList.remove('-active') })
      nav.forEach((item, i) => { item.classList.remove('-active') })


      items[index].classList.add('-active')
      bgs[index].classList.add('-active')
      nav[index].classList.add('-active')

      current = items[index]
       
      box = qs('.slider-box-crop', current)
      boxCrop = qs('.slider-info', current)
      tags = qs('.slider-tags', current)
      tagsCrop = qs('.slider-tags-crop', current)

      tl.fromTo(box, {x: '-101%'}, {duration: 1, x: '0%', ease: this.custom  })
      tl.fromTo(boxCrop, { x: '101%' }, {duration: 1, x: '0%', ease: this.custom  }, '-=1')
      tl.fromTo(tags, { x: '-101%'}, {duration: 1, x: '0%', ease: this.custom }, '-=0.8')
      tl.fromTo(tagsCrop, {x: '101%'}, {duration: 1, x: '0%', ease: this.custom  }, '-=1')
      nr.innerHTML = index < 10 ? `00${index + 1}` :  `0${index + 1}`

      this.els.forEach((el, i) => {
        gsap.to( el.material.uniforms.uHover, { value: 1, duration: 1, ease: this.custom })
      })


      if(this.els[index]) {
       gsap.to( this.els[index].material.uniforms.uHover, { value: 0, duration: 1, ease: this.custom })
      } 
  }

  resize() {
    super.resize()
    this.state.resize = true
    //this.getOffset()
    setTimeout(()=> {

      Emitter.emit('smooth:resize')
      this.getOffset()
    }, 100)

   

    this.state.resize = false
  }


}