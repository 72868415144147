import EventEmitter from 'tiny-emitter'
import store from '../store'
import { Emitter } from '../core'

export default class Sizes extends EventEmitter {
  constructor() {
    super()

    this.width = store.sizes.vw
    this.height = store.sizes.vh
    this.pixelRatio = Math.min(window.devicePixelRatio, 2)
    this.breakpoints =  {
      XS: window.innerWidth <= 500,
      S: window.innerWidth <= 749,
      S_UP: window.innerWidth >= 501,
      M: window.innerWidth <= 1024,
      M_UP: window.innerWidth >= 750,
      L: window.innerWidth >= 1025,
    }

    this.addEvents()
  }

  addEvents() {
    Emitter.on('resize', () => {
      this.width =  window.innerWidth //store.sizes.vw
      this.height = window.innerHeight //store.sizes.vw
      this.pixelRatio = Math.min(window.devicePixelRatio, 2)
      this.breakpoints =  {
        XS: window.innerWidth <= 500,
        S: window.innerWidth <= 749,
        S_UP: window.innerWidth >= 501,
        M: window.innerWidth <= 1024,
        M_UP: window.innerWidth >= 750,
        L: window.innerWidth >= 1025,
      }

      this.emit('resize')
    })
  }
}
