
import store from "../store";
import { bounds } from "../utils";
import WebGlManager from "../webgl/WebGlManager"
import Stick from './Stick';
import gsap  from 'gsap';

export default class StudioGallery extends Stick {

  constructor(obj) {
    super(obj)

    this.manager = new WebGlManager()
    this.state.index = 0
    this.state.animate = false
    this.els = this.manager.world.studio
  }

  setup() {
    super.setup()
    const { sniff, sizes } = store
    const obj = {width: sizes.vh}
    const itemsHeight =  sniff.isDevice ?  [obj,obj]  : [obj]   
    this.bounds = this.calculateProgressRanges(itemsHeight)
  }

  onLeave () {
    const { sticky } = this.dom

    this.state.fixed = false
    if(!this.isSmooth) {
      sticky.classList.remove('-fixed')
      gsap.set(sticky, { marginTop: `200vh` })
    }
  }

  onUpdate (self) {
    
    super.onUpdate(self)
    const { sniff } = store
    const { index } = this.state 
    const { items } = this.dom

    this.bounds.forEach( (bounds, i) => {
      const mapedProgress = this.mapValueToRange(self.progress, bounds)
      const id = bounds.index 
      if(this.els && this.els[0]) this.els[0].material.uniforms.uHover.value = mapedProgress 
      if(bounds.end > self.progress && bounds.start < self.progress) {
        this.state.index = bounds.index
      }
    })

    if( sniff.isDevice && !this.state.animate && index !== this.state.index) {
      this.state.animate = true
      gsap.to(items, { autoAlpha: 0, duration: 0.35  })
      gsap.to(items[ this.state.index ], { autoAlpha: 1, duration: 0.35, onComplete: () => {
        this.state.animate = false
      }})
    }


    if(this.els && this.els[0]) {
      this.els.forEach( (el)=> {
        el.state.posY = this.settings.posY
        el.state.index.current = this.state.index
      })
    }
     
  }

  mapValueToRange(value, range) {
    if (value < range.start) return value - range.start;
    if (value > range.end) return 1;
    return (value - range.start) / (range.end - range.start);
  }

  calculateProgressRanges(slides) {

    // Calculate the total width of all slides
    const totalWidth = slides.reduce((sum, slide) => sum + slide.width, 0);
   
    // Calculate the offset as half the screen width
    const screenOffset = 0;

    let accumulatedWidth = 0;
    
    return slides.map((slide, index) => {
        const start = (accumulatedWidth - screenOffset) / (totalWidth );
        accumulatedWidth += slide.width;
        const end = (accumulatedWidth - screenOffset) / (totalWidth );
        
        // Calculate the mid and offset (75%) values
        const mid = start + (end - start) / 4;
        const offset75 = start + (end - start) * 0.75;
        const offset25 = start + (end - start) * 0.25;
        const preMidOffset = start - (end - start);
        
        return {
            index: index,
            start: Math.max(0, start), // Ensure start does not go below 0
            mid: Math.max(0, mid),     // Ensure mid does not go below 0
            offset25: Math.max(0, offset25), // Ensure offset does not go below 0
            offset75: Math.max(0, offset75), // Ensure offset does not go below 0
            end: Math.min(1, end),      // Ensure end does not go above 1
            start50: Math.max(0, preMidOffset),
        };
    });
  }
} 
