import { qs, qsa, growTextarea } from "../utils"
import gsap from "gsap"


export default class Forms {
  constructor() {

    this.dom =  {
      forms: qsa('form'),
      formDefault: qsa('.contact-default .char-0'),
      formSuccess: qsa('.contact-success .char-0'),
      inputs: qsa('input'),
      select: qs('select'),
      text: qs('textarea'),
      icons: qsa('.confirm-icon'),
      message: qs('.contact-message'),
      customSelect: qs('.custom-select')
    }

    growTextarea()

    this.init()
  }

  handleSubmit = (e, form) => {
    e.preventDefault()
   
    const formData = new FormData(form)

    fetch(form.getAttribute('action'), {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        this.updateForm(form)

      })
      .catch((error) => console.log(error))
  }

  handleSelect() {


    const icon = `<svg class="arrow-icon"><use xlink:href="#rightarrow"></use></svg>`
    

    let x, i, j, l, ll, selElmnt, a, b, c, d;
    x = document.getElementsByClassName("custom-select");
    l = x.length;
    d = document.createElement("DIV");
    d.setAttribute("class", "select-icon");
    d.innerHTML = icon

    for (i = 0; i < l; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      
     

      ll = selElmnt.length;
      /* For each element, create a new DIV that will act as the selected item: */
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.setAttribute('name', 'budget')
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);


      
      /* For each element, create a new DIV that will contain the option list: */
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < ll; j++) {
        /* For each option in the original select element,
        create a new DIV that will act as an option item: */
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", (e)=> {
            /* When an item is clicked, update the original select box,
            and the selected item: */
            const target = e.target
            var y, i, k, s, h, sl, yl;
            s = target.parentNode.parentNode.getElementsByTagName("select")[0];
            sl = s.length;
            h = target.parentNode.previousSibling;
            for (i = 0; i < sl; i++) {
              if (s.options[i].innerHTML == target.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = target.innerHTML;
                y = target.parentNode.getElementsByClassName("same-as-selected");
                yl = y.length;
                for (k = 0; k < yl; k++) {
                  y[k].removeAttribute("class");
                }
                target.setAttribute("class", "same-as-selected");
                break;
              }
            }
            h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b)
      a.appendChild(d)
      a.addEventListener("click", (e)=> {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */

        const target = e.target
        e.stopPropagation();
        this.closeAllSelect(target);
        target.nextSibling.classList.toggle("select-hide");
        target.classList.toggle("select-arrow-active");
        this.validateInput({target: this.dom.select})
      });
    }
   

  }

  closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
    except the current select box: */
    const icon = `<svg class="arrow-icon"><use xlink:href="#rightarrow"></use></svg>`
    var x, y, i, xl, yl, d, arrNo = [];


    d = document.createElement("DIV");
    d.setAttribute("class", "select-icon");
    d.innerHTML = icon
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
   
   
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        
        arrNo.push(i)
        elmnt.appendChild(d)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }


  updateForm(form) {

    const {  formDefault, formSuccess, icons, message } = this.dom
    gsap.fromTo(formDefault, {y: '0%'}, { duration: 0.5, y: '-200%', stagger: 0.05, ease: 'power2.inOut' })
    gsap.fromTo(formSuccess, {y:'200%'}, { duration: 0.5, delay: 0.2, y: '0%', stagger: 0.05, ease: 'power2.inOut' })
    gsap.to(icons, {autoAlpha: 0})
    gsap.fromTo(message, { autoAlpha: 0, y: '100%'}, { autoAlpha: 1, y: '0%'})
    form.reset()

    setTimeout(()=> {
      gsap.fromTo(formSuccess, {y:'0%'}, { duration: 0.5, y: '200%', stagger: 0.05, ease: 'power2.inOut' })
      gsap.fromTo(formDefault, {y: '-200%'}, { duration: 0.5, y: '0%', delay: 0.2, stagger: 0.05, ease: 'power2.inOut' })
      gsap.fromTo(message, { autoAlpha: 1, y: '0%'}, { autoAlpha: 0, y: '100%'})
    }, 3000)

  }
  
  showLabel = (e) => {
    const target = e.target
    const label = target.previousElementSibling
    gsap.to(label, {autoAlpha:1})
  }

  validateInput = (e) => {
    const target = e.target
    const value = target.value.length
    const field = qs(`.confirm-${target.name}`)

    if(!field) return

    const svg = qs('svg', field)

    if(value > 1) {
      gsap.to(svg, { autoAlpha: 1})
    } else {
      gsap.to(svg, { autoAlpha: 0})
    }
  }

  handleError = (e, form) => {
    const required = qsa('[required]', form)

    required.forEach((item) => {
      const value = item.value.length
      const parent = item.parentNode

      if (value < 1) {
        parent.classList.add('-error')
      } else {
        parent.classList.remove('-error')
      }
    })
  }

  on() {
    const {forms, inputs, select, text} = this.dom
    forms.forEach((form)=> {
      form.addEventListener('submit', (e)=> this.handleSubmit(e, form))
    })

    inputs.forEach((input)=> {
     // input.addEventListener('keydown', this.showLabel)
      input.addEventListener('keyup', this.showLabel)
      input.addEventListener('change', this.validateInput)
    })

    select && select.addEventListener('change', this.showLabel) 
    select && select.addEventListener('change', this.validateInput) 

    text && text.addEventListener('keyup', this.showLabel)
    text && text.addEventListener('change', this.validateInput)

    this.handleSelect()

  }

  init() {
    const {forms} = this.dom

    if(!forms[0]) return

    this.on()
  }
}