import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import WebGlManager from './WebGlManager'

export default class Camera {
  constructor() {
    this.manager = new WebGlManager()
    this.sizes = this.manager.sizes
    this.canvas = this.manager.canvas

    this.setInstance()
    this.setOrbitControls()
  }

  setInstance() {
    
    this.instance = new THREE.PerspectiveCamera(
      35,
      this.sizes.width /  this.sizes.height,
      0.1,
      100
    )

    this.instance.position.set(0, 0, 5)
  }

  setOrbitControls() {

    this.controls = new OrbitControls(this.instance, this.canvas)
    this.controls.enableDamping = true
    this.controls.enabled = true
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height
    this.instance.updateProjectionMatrix()
  }

  update() {
    this.controls.update()
  }
}
