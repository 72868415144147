import * as THREE from 'three'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import EventEmitter from 'tiny-emitter'
import { Emitter } from '../core'

export default class Resources extends EventEmitter {
  constructor(sources) {
    super()

    if(!sources) return
    this.sources = sources
    this.items = {}
    this.toLoad = this.sources.length
    this.loaded = 0

    this.setLoaders()
    this.startLoading()
  }

  setLoaders() {
    this.loaders = {}
    this.loaders.dracoLoader = new DRACOLoader()
    this.loaders.dracoLoader.setDecoderPath('/models/draco/')
    this.loaders.gltfLoader = new GLTFLoader()
    this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader)
    this.loaders.textureLoader = new THREE.TextureLoader()
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
  }

  startLoading() {
    for (const source of this.sources) {
      

      switch (source.type) {
        case 'gltfModel':
          this.loaders.gltfLoader.load(source.path, (file) => {
            this.loadSource(source, file)
          })
          break

        case 'texture':
          this.loaders.textureLoader.load(source.path, (file) => {
            this.loadSource(source, file)

            // change filter and diable mip maps for the moment
            // file.magFilter = THREE.LinearFilter
            // file.generateMipmaps = false
          })
          break

        case 'cubeTexture':
          this.loaders.cubeTextureLoader.load(source.path, (file) => {
            this.loadSource(source, file)
          })
          break
      }
    }
  }

  loadSource(source, file) {
    this.emit('loading', { loaded: this.loaded, total: this.toLoad })

    this.items[source.name] = file
    this.loaded++

    if (this.loaded === this.toLoad) {
      this.emit('ready')
    }
  }
  
}
