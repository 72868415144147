import store from '../store'
import Emitter from './Emitter'
import gsap from 'gsap'
//import { ScrollTrigger } from '../vendor/ScrollTrigger'

export default class GlobalRaf {
  constructor() {
    

    this.scroll = {
      current: 0,
      limit: 0,
      velocity: 0,
      direction: 0,
      progress: 0,
    }

    this.mouse = {
      x: 0,
      y: 0,
      target: null,
      lastX: 0, 
      lastY: 0,
      distances: [],
      timeIntervals: [],
      strength: {
        speed: 0,
        acceleration: 0,
      }
    }

  }

  tick = (a, b, c) => {
    Emitter.emit('tick', {
      time: a,
      deltaTime: b,
      frame: c,
      scroll: this.scroll,
      mouse: this.mouse,
    })

    //ScrollTrigger.update()
  }

  onScroll = ({ scroll, limit, velocity, direction, progress }) => {
    if (store.flags.locked) return
    this.scroll = {
      current: scroll,
      limit,
      velocity,
      direction,
      progress,
    }
  }

  getAverageSpeed() {
    const { distances, timeIntervals } = this.mouse;
    const totalDistance = distances.reduce((acc, dist) => acc + dist, 0);
    const totalTime = timeIntervals.reduce((acc, interval) => acc + interval, 0);
    return totalDistance / (totalTime / 1000); // pixels per second
  }

  getAcceleration() {
    const { distances, timeIntervals } = this.mouse;
    const speeds = distances.map((dist, i) => dist / (timeIntervals[i] / 1000));
    const accelerations = speeds.map((speed, i, arr) => i > 0 ? (speed - arr[i - 1]) / (timeIntervals[i] / 1000) : 0);
    return accelerations.reduce((acc, accel) => acc + accel, 0) / accelerations.length;
  }

  getStrength() {
    const speed = this.getAverageSpeed();
    const acceleration = this.getAcceleration();
    return { speed, acceleration };
  }

  move = ({ x, y, target, lastX, lastY,timeIntervals }) => {

    const currentTime = new Date().getTime();

    this.mouse.x = x
    this.mouse.y = y
    this.mouse.target = target
    this.mouse.strength = this.getStrength()


    if (lastX && lastY) {
      const deltaX = x - lastX
      const deltaY = y - lastY
      const distance = Math.sqrt(deltaX * deltaY + deltaY * deltaY)
      const timeInterval = currentTime - (timeIntervals[timeIntervals.length - 1] || currentTime)
      
      this.mouse.distances.push(distance)
      this.mouse.timeIntervals.push(timeInterval)
    }

    this.mouse.lastX = x
    this.mouse.lastY = y
  }



  on() {
    gsap.ticker.add(this.tick)
    Emitter.on('scroll', this.onScroll)
    Emitter.on('mousemove', this.move)
  }
}
