import * as THREE from 'three'
import store from '../store'
import { Emitter, Core } from '../core'
import Sizes from './Sizes'
import Camera from './Camera'
import Renderer from './Renderer'
import Resources from './Resources'
import World from './World'
import sources from './sources'
import { qsa} from '../utils'

let instance = null

export default class WebGlManager  {

  constructor(obj) {
    if (!instance) {
      instance = this
    } else {
      return instance
    }
   

    instance = this
    this.canvas = obj.el
    this.core = new Core()
    this.resources = new Resources(sources)
    this.taxi = this.core.taxi
    this.sizes = new Sizes()
    this.time = new THREE.Clock()
    this.scene = new THREE.Scene()
    this.container = new THREE.Group()
    this.camera = new Camera()
    this.renderer = new Renderer()

    this.updateAssets()

  }

  createWorld() {
   this.world = new World()
  }

  update = (e) => {

    this.world.update({
      current: e.current, 
      last: e.target,
      mouse: e.mouse
    })

    this.camera.update()
    this.renderer.update()
    
  }

  resize = ()=> {
    this.camera.resize()
    this.renderer.resize()
    this.world.resize()
  }

  on() {
    Emitter.on('tick', this.update)
    Emitter.on('smooth:resize', this.resize)
    //Emitter.on('resize', this.resize)

  }
  
  updateAssets() {
   
  }

  onEnter() {
    this.world && this.world.onEnter()
  }

  onLeave() {
   this.world && this.world.onLeave()
  }

  getViewSize() {
    const camera = this.camera.instance
    const fovInRadians = (camera.fov * Math.PI) / 180
    const height = Math.abs(camera.position.z * Math.tan(fovInRadians / 2) * 2)
    return { width: height * camera.aspect, height }
  }

}