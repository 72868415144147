
import store from '../store';
import Stick from './Stick';
import { qs, qsa, bounds } from '../utils';
import WebGlManager from "../webgl/WebGlManager"
import gsap  from 'gsap';

export default class HomeProjects extends Stick {

  constructor(obj) {
    super(obj)

    this.manager = new WebGlManager()
    this.els = this.manager.world.projects
    this.bgs = this.manager.world.backgrounds
    this.state.index = 0
    

  }

  setup() {
    super.setup()
    this.bounds = []
    const { items } = this.dom
    const { totalItems } = this.settings

    const links = qsa('.box-link')
    const projectLink = qsa('.projects-link')
    const titles = qsa('.project-title')
    const dates = qsa('.project-date')
    const itemsWidth = []

    Object.assign(this.dom, {
      titles,
      dates,
      links,
      projectLink
    })

    items.forEach( (item, i) => {
      const rect = bounds(item)
      const obj = { width: rect.width}
      itemsWidth.push(obj )
    })


    this.bounds = this.calculateProgressRanges(itemsWidth)
    this.on()
  }


  beforeEnter(self) {
    super.beforeEnter(self)

    if(this.els && this.els[0]) {
      this.els[0].material.uniforms.uHover.value = 1 - this.settings.enter
    }
  }

  roundToTwoDecimalPlaces(num) {
    const epsilon = Number.EPSILON;
    const smallValue = 1e-3;  // A small value to ensure proper rounding
    return Math.round((num + epsilon + smallValue) * 100) / 100;
  }

  onUpdate (self) {
    
    super.onUpdate(self)
    const { items } = this.dom
    gsap.set(items, { x: -self.progress * this.settings.fullWidth })


   

      this.bounds.forEach( (bounds, i) => {

        const mapedProgress = this.mapValueToRange(self.progress, bounds)
        const id = bounds.index 
      
        if(this.els && this.els[id]) this.els[id].material.uniforms.uHover.value = 1 - mapedProgress
  
        if(self.progress > bounds.start50 && self.progress < bounds.mid) {
          this.state.index = bounds.index
          this.updateBox()
        }
  
        if(self.progress > bounds.end) {
          if(this.els && this.els[id]) this.els[id].material.uniforms.uHover.value = 0
        }
      })
      
      if(this.els && this.els[0]) {
        this.els.forEach( (el,i)=> { 
          if (el.name == 'background') return
          el.state.posX = -this.settings.posX
          el.state.posY = this.settings.posY
    
        })
      }
  
      if(this.bgs && this.bgs[0]) {
        this.bgs[0].state.posY = this.settings.posY
      }

    
  }

  mapValueToRange(value, range) {
    if (value < range.start50) return value - range.start50;
    if (value > range.start) return 1;
    return (value - range.start50) / (range.start - range.start50);
  }

  calculateProgressRanges(slides) {

    const { totalItems } = this.settings
    const screenWidth = store.sizes.vw + 10

    // Calculate the total width of all slides
    const totalWidth = slides.reduce((sum, slide) => sum + slide.width, 0);
    const slideWidth = slides[totalItems - 1].width;
    // Calculate the offset as half the screen width
    const screenOffset = 0;

    let accumulatedWidth = 0;
    
    return slides.map((slide, index) => {
        const start = (accumulatedWidth - screenOffset) / (totalWidth - slideWidth);
        accumulatedWidth += slide.width;
        const end = (accumulatedWidth - screenOffset) / (totalWidth - slideWidth);
        
        // Calculate the mid and offset (75%) values
        const mid = start + (end - start) / 4;
        const offset75 = start + (end - start) * 0.75;
        const offset25 = start + (end - start) * 0.25;
        const preMidOffset = start - (end - start);
        
        return {
            index: index,
            start: Math.max(0, start), // Ensure start does not go below 0
            mid: Math.max(0, mid),     // Ensure mid does not go below 0
            offset25: Math.max(0, offset25), // Ensure offset does not go below 0
            offset75: Math.max(0, offset75), // Ensure offset does not go below 0
            end: Math.min(1, end),      // Ensure end does not go above 1
            start50: Math.max(0, preMidOffset),
        };
    });
  }
  
  onLeave () {
    const { sticky } = this.dom

    this.state.fixed = false
    if(!this.isSmooth) {
      sticky.classList.remove('-fixed')
      gsap.set(sticky, { marginTop: `500vh` })
    }
  }

  updateBox() {

    if(this.state.animate) return

    this.state.animate = true

    const {titles, dates, links} = this.dom
    const currentTitle = titles[this.state.index]
    const currentDate = dates[this.state.index]
    const currentLink = links[this.state.index]

    gsap.set(titles, { autoAlpha: 0, y: '100%'}, 0)
    gsap.set(dates, { autoAlpha: 0,  y: '100%'}, 0)
    gsap.set(links, { autoAlpha: 0}, 0)
    gsap.set(currentTitle, { autoAlpha: 1, y: '0%'})
    gsap.set(currentDate, { autoAlpha: 1, y: '0%'})
    gsap.set(currentLink, { autoAlpha: 1})
    
    setTimeout(()=> {
      this.state.animate = false
    },100)
    
  }

  onMouseEnter(e) {

  }

  onMouseLeave(e) {

  }

  on() {
  
  }

  

} 