import * as THREE from 'three'
import store from '../store'
import WebGlManager from './WebGlManager'
import GLObject from './GLObject'
import { bounds, qs } from '../utils'
import gsap from 'gsap'

const vertex = /* glsl */`

  precision mediump float;
  varying vec2 vUv;
  uniform float uAlpha;

  void main() {
    vUv = uv;
    float n =uAlpha;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

const fragment = /* glsl */ `
  precision mediump float;
  varying vec2 vUv;
  uniform vec2 uRepeat;
  uniform float uAlpha;
  uniform sampler2D uTexture;
  uniform vec2 uRes;
  uniform vec2 uPlaneSize;
  uniform vec2 uTextureSize;

  float random (in vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898,78.233)))* 43758.5453123);
  }

  float noise (in vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    // Four corners in 2D of a tile
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));

    // Smooth Interpolation

    // Cubic Hermine Curve.  Same as SmoothStep()
    vec2 u = f*f*(3.0-2.0*f);
    // u = smoothstep(0.,1.,f);

    // Mix 4 coorners percentages
    return mix(a, b, u.x) +
            (c - a)* u.y * (1.0 - u.x) +
            (d - b) * u.x * u.y;
  }

  vec2 uvCover(vec2 uv, vec2 size, vec2 resolution) {
    vec2 coverUv = uv;
    vec2 s = resolution; // Screen
    vec2 i = size; // Image

    float rs = s.x / s.y;
    float ri = i.x / i.y;
    vec2 new = rs < ri ? vec2(i.x * s.y / i.y, s.y) : vec2(s.x, i.y * s.x / i.x);
    vec2 offset = (rs < ri ? vec2((new.x - s.x) / 2.0, 0.0) : vec2(0.0, (new.y - s.y) / 2.0)) / new;
    
    coverUv = coverUv * s / new + offset;

    return coverUv;
  }

  vec2 backgroundUV (vec2 uv, vec2 resolution, vec2 texResolution) {
    float tAspect = texResolution.x / texResolution.y;
    float pAspect = resolution.x / resolution.y;
    float pwidth = resolution.x;
    float pheight = resolution.y;
    
    float width = 0.0;
    float height = 0.0;  
    if (tAspect > pAspect) {
      height = pheight;
      width = height * tAspect; 
    } else {
      width = pwidth;
      height = width / tAspect;
    }
    float x = (pwidth - width) / 2.0;
    float y = (pheight - height) / 2.0;
    vec2 nUv = uv;
    nUv -= vec2(x, y) / resolution;
    nUv /= vec2(width, height) / resolution;
    return nUv;
  }

  void main() {

    vec2 uv = vUv;

    //vec2 uv = backgroundUV(vUv, uPlaneSize, uTextureSize);

    vec2 pos = vec2(uv*(uRepeat)) * 2.;

    uv *= uRepeat;
    uv = fract(uv);

    float n = noise(pos);

    vec3 img = texture2D(uTexture, uv+n*(1.-uAlpha)).rgb;
    
    gl_FragColor = vec4(img, 1.);

  }
`

const hPlaneGeometry = new THREE.PlaneGeometry(1, 1)
let instance = null

export default class HeroGl extends GLObject {
  constructor(index, opts = {}) {
    super()

    if (!instance) {
      instance = this
    } else {
      return instance
    }

    instance = this
    this.index = index
    this.opts = opts
    this.name = 'herogl'
    this.manager = new WebGlManager()
    this.time = this.manager.time
    this.scene = this.manager.scene
    this.container = this.manager.container
    this.sizes = this.manager.sizes
    this.group = new THREE.Group()
    this.resources = this.manager.resources
    this.renderer = this.manager.renderer.instance
    this.tween = null
    this.texture = {}
    this.modelSize =  this.sizes.breakpoints.M_UP ? 0.65 : 0.4
    this.hero = qs('.hero-banner')
    this.canvas = qs('.hero-canvas')
    this.point = new THREE.Vector3(0,0.5,0)
    this.point2 = new THREE.Vector3(0,0,0)
    

    this.needsToRun = false


  }

  init(el) {
    super.init(el)
    this.containerSize = new THREE.Vector2(el.clientWidth, el.clientHeight)
    this.setModel()
    this.createFrontMesh()
    this.canvasTexture()

  }


  setModel() {

    let model = this.resources.items.logo.scene
    let text1 = this.resources.items.logoTexture1
    let text2 = this.resources.items.logoTexture2
    let circle = model.children.filter((el)=> el.name == 'NL_Circle' )[0]
    let squiggle = model.children.filter((el)=> el.name == 'Squiggle' )[0]
    let geometry1 = squiggle.geometry
    let geometry2 = circle.geometry
   
    geometry2.computeBoundingBox()
    let material1 = new THREE.MeshMatcapMaterial({
      matcap: text1,
      transparent: true
    })

    let material2 = new THREE.MeshMatcapMaterial({
      matcap: text2,
      color: '#fafafa',
      transparent: true
    })

    this.squiggle = new THREE.Mesh(geometry1, material1)
    this.circle = new THREE.Mesh(geometry2, material2)
    this.squiggle.scale.set(this.modelSize, this.modelSize , this.modelSize)
    this.circle.scale.set(this.modelSize, this.modelSize , this.modelSize)
    this.squiggle.position.z = 0.8
    
    this.circle.position.z = 0.8
    this.circle.rotation.y = -38 * Math.PI / 180
    this.circle.rotation.x = -39 * Math.PI / 180

    this.container.add(this.squiggle)
    this.container.add(this.circle)
    //this.scene.add(this.container)
   
  }


  canvasTexture() {
   
    const ctx = this.canvas.getContext('2d')
    const dpr =  Math.min(window.devicePixelRatio, 2)
    const texture = new THREE.CanvasTexture(this.canvas)
    const rect = bounds(this.hero)
    const ww = this.sizes.breakpoints.M_UP ? 120 : 100
    const hh = this.sizes.breakpoints.M_UP ? 48 : 40
    const qx = Math.floor(rect.width / ww)
    const qy = Math.floor(rect.height / hh)
    
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    texture.minFilter = THREE.NearestFilter
    // texture.magFilter = THREE.NearestFilter
    // texture.generateMipmaps = false


    // texture.repeat.set( qx, qy )
    this.uniforms.uRepeat.value = [qx,qy]

    // https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio

    let w = 240
    let h = 96
    let scale = dpr // which is typically 2 on retinas, it make it look better than before. Bumping to 3 makes it look a bit smoother but not sure how much it will affect performance (probably not much since it's just a canvas animation?) It still looks a bit aliased though.

    // Set display size (css pixels).
    this.canvas.style.width = `${w}px`;
    this.canvas.style.height = `${h}px`;

    this.canvas.width = Math.floor(w * scale);
    this.canvas.height = Math.floor(h * scale);
    
    // Normalize coordinate system to use CSS pixels.
    ctx.scale(scale, scale);

    //this.material.map = texture
    this.uniforms.uTexture.value = texture

    const t1 = {
      x1: 2,
      y1: 40,
      x2: 154,
      y2: 40,
      x3: 126 
    }
    
    const t2 = {
      x1: 2,
      y1: 54,
      x2: 154,
      y2: 54,
      x3: 126
    }
    
    const tl = gsap.timeline({
     repeatDelay: 0.5,
     repeat: -1
    })

    gsap.set(t1, {scale: 0.5})

    tl.to(t1, { duration: 1, x1: 114, ease: 'power2.inOut'})
    tl.to(t1, { duration: 1, y2: 90, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, y2: 104, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, x1: 114, ease: 'power2.inOut'}, '-=1')
    tl.to(t1, { duration: 1, x3: 86, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, x3: 86, ease: 'power2.inOut'}, '-=1')
    
    tl.to(t1, { duration: 1, y1: 90, ease: 'power2.inOut', delay: 0.5})
    tl.to(t1, { duration: 1, x2: 3, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, y1: 104, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, x2: 3, ease: 'power2.inOut'}, '-=1')
    tl.to(t1, { duration: 1, x3: 126, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, x3: 126, ease: 'power2.inOut'}, '-=1')
    
    tl.to(t1, { duration: 1, x1: 3, ease: 'power2.inOut', delay: 0.5})
    tl.to(t1, { duration: 1, y2: 40, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, y2: 54, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, x1: 3, ease: 'power2.inOut'}, '-=1')
    tl.to(t1, { duration: 1, x3: 86, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, x3: 86, ease: 'power2.inOut'}, '-=1')
    
    tl.to(t1, { duration: 1, y1: 40, ease: 'power2.inOut', delay: 0.5})
    tl.to(t1, { duration: 1, x2: 154, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, y1: 54, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, x2: 154, ease: 'power2.inOut'}, '-=1')
    tl.to(t1, { duration: 1, x3: 126, ease: 'power2.inOut'}, '-=1')
    tl.to(t2, { duration: 1, x3: 126, ease: 'power2.inOut'}, '-=1')

    Object.assign(this.texture, {
      tl,
      ctx,
      t1,
      t2,
      dpr,
      texture
    })

  }


  updateTexture() {

    const {ctx, t1, t2} = this.texture

    ctx.clearRect(0, 0, 240, 96)
    ctx.beginPath()
    ctx.rect(0, 0, 240, 96)
    ctx.fillStyle = "#1e1919"
    ctx.fill()
    ctx.fillStyle =  `rgba(150, 150, 150, ${this.uniforms.uAlpha.value})`
    ctx.letterSpacing = '-0.06em'
    ctx.font = '47px PPNeueMontreal'
    ctx.fillText('Linear', t1.x1, t1.y1)
    ctx.fillText('_', t1.x3, 18)
    ctx.fillText('Non', t1.x2, t1.y2)
    ctx.save()
    ctx.rotate(180 * Math.PI / 180)
    ctx.translate(-240, -110)
    ctx.fillText('Linear', t2.x1, t2.y1)
    ctx.fillText('_', t2.x3, 32)
    ctx.fillText('Non', t2.x2, t2.y2)
    ctx.restore()

    this.uniforms.uTexture.value.needsUpdate = true

  }

  createFrontMesh() {

    this.uniforms = {
      uTexture: {value: null},
      uRepeat: {value: [0,0]}, 
      uAlpha: { value: 0},
      uRes: {value:new THREE.Vector2( store.sizes.vw, store.sizes.vh )},
      uTextureSize: { value: new THREE.Vector2(240, 96) },
      uPlaneSize: { value: new THREE.Vector2(this.containerSize.x, this.containerSize.y) },
    }

    this.material = new THREE.ShaderMaterial({
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: this.uniforms
    })
  

    this.bgTexture = new THREE.Mesh(hPlaneGeometry, this.material)
    this.group.add(this.bgTexture)
    this.add(this.group)
    //this.scene.add(this)

  }

  animateIn() {
   this.needsToRun = true
   this.onEnter()
   gsap.to(  this.squiggle.material, {opacity: 1} )
   gsap.to(  this.circle.material, {opacity: 1} )

  }  

  animateOut() {
    gsap.to(  this.squiggle.material, { duration: 0.25, opacity: 0} )
    gsap.to(  this.uniforms.uAlpha, { duration: 0.25, value: 0} )
    gsap.to(  this.circle.material, { duration: 0.25, opacity: 0, 
      onComplete: ()=> {
        this.needsToRun = false
        this.onLeave()
      }
    })
  }  

  resize() {
    super.resize()

    this.modelSize =  this.sizes.breakpoints.M_UP ? 0.65 : 0.4
    const rect = bounds(this.hero)
    const ww = this.sizes.breakpoints.M_UP ? 120 : 60
    const hh = this.sizes.breakpoints.M_UP ? 48 : 24
    const qx = Math.floor(rect.width / ww)
    const qy = Math.floor(rect.height / hh)

    if(this.texture.texture) {
      this.texture.texture.repeat.set(qx,qy)
      this.uniforms.uRepeat.value = [qx,qy]
    }

   if(this.squiggle) this.squiggle.scale.set(this.modelSize, this.modelSize , this.modelSize)
   if(this.circle)  this.circle.scale.set(this.modelSize, this.modelSize , this.modelSize)

   this.containerSize = new THREE.Vector2(this.el.clientWidth, this.el.clientHeight)

  }

  onEnter() {
    this.el = qs('[data-hero]')
    this.hero = qs('.hero-banner')
    this.canvas = qs('.hero-canvas')

    this.updateDOM(this.el)
    this.scene.add(this)
    this.scene.add(this.container)
    
  }

  onLeave() {
    this.scene.remove(this)
    this.scene.remove(this.container)
  }
  
  update() {

    if(!this.needsToRun) return

    this.updateTexture()

    const elapsedTime = this.time.getElapsedTime()
    this.squiggle.position.y = this.position.y
    this.circle.position.y = this.position.y
    this.squiggle.rotation.y = 2 * elapsedTime
    this.circle.rotation.z = -1.15 * elapsedTime
   
    
  }
}
