import { qs } from "../utils"
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { CustomEase } from '../vendor/CustomEase'
import gsap from "gsap"


export default class Reel {

  constructor() {

    const custom =  CustomEase.create('custom', 'M0,0 C0.1,0 0.148,0.034 0.182,0.136 0.216,0.238 0.239,0.685 0.448,0.886 0.55,0.984 0.704,1 1,1')

    this.state =  {
      on: false,
      isAnimating: false
    }

    this.dom = {
      button: qs('.reel-sound'),
      video: qs('.reel-video video'),
      iconOn: qs('.sound-on'),
      iconOff: qs('.sound-off'),
      custom
    }


    this.isVisible()

    if(this.dom.button) this.on()
  }

  soundState = () => {
    if(!this.state.on) this.soundOn()
    if(this.state.on) this.soundOff()
  }


  soundOn = () => {
    
    const { video, iconOff, iconOn, custom} = this.dom

    if(this.state.isAnimating) return

    this.state.on = true
    this.state.isAnimating = true
    video.muted = false
    
    gsap.to( iconOff, {duration: 0.8, y: -200, ease: custom} )
    gsap.to( iconOn, {duration: 0.8, y: '-50%', delay: 0.1, ease: custom, onComplete: ()=> {
      this.state.isAnimating = false
    }} )

  }
  
  isVisible() {

    const { video } = this.dom

    ScrollTrigger.create({
      trigger: video,
      start: 'top top+=100%',
      end: 'bottom bottom-=100%',
      scrub: true,
      onEnter: () => {
        this.onEnter()
      },
      onEnterBack: () => {
        this.onEnter()
      },
      onLeaveBack: () => {
        this.onLeave()
      },
      onLeave: () => {
        this.onLeave()
      },
    })
  }

  onEnter() {
    const { video } = this.dom
    if(video) video.play()
  }

  onLeave() {
    const { video } = this.dom
    if(video) video.pause()
  }


  soundOff = ()=> {

    const { video, iconOff, iconOn, custom } = this.dom

    if(this.state.isAnimating) return
    
    this.state.on = false
    this.state.isAnimating = true
    video.muted = true

    gsap.to( iconOn, {duration: 0.8, y: 200, ease: custom} )
    gsap.to( iconOff, {duration: 0.8, y: '-50%', delay: 0.1, ease: custom, onComplete: ()=> {
      this.state.isAnimating = false
    }} )

  }

  on() {
    const { button } = this.dom
    button.addEventListener('click', this.soundState)
  }
}