import store from '../store'
import { Renderer } from '@unseenco/taxi'
import { Core, Emitter, AnimationManager } from '../core'
import { Splits, Menu, Smooth, HomeProjects, Forms, Reel, StudioGallery, GalleryProjects, InfiniteWorks } from '../components'
import { qs, qsa, getid , formFocus, growTextarea, bounds } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import gsap from 'gsap'

export default class Default extends Renderer {
  constructor(opt = {}) {
    super(opt)

    this.core = new Core()
    this.isResizing = false
    this.smoothScroll = null
   
  }

  onEnter() {
    const { sniff, body } = store
    if (sniff.isDevice) body.classList.add('is-device')
    store.page = this.wrapper.lastElementChild
  }

  onEnterCompleted() {
    this.on()
    this.animations = new AnimationManager()
    if (this.core.ready) this.ready()
    
  }

  ready = () => {

    this.getDom()
    this.components()
    this.smooth() 
    ScrollTrigger.refresh()
    this.animations.onScroll()
    this.core.webgl.onEnter()
    this.animations.in()
  }

  readyCompleted = () => {
    store.flags.locked = false
  }

  getDom() {

    this.dom = {
      deskMenu: qs('.header'),
      mobMenu: qs('.mobile'),
      footer: qs('.footer'),
      open: qs('.burger'),
      close: qs('.close'),
      reel: qs('.reel-video video'),
      sound: qs('.reel-sound'),
      projects: qs('.projects'),
      gallery: qs('.gallery'),
      projectGallery: qs('.galleryProjects'),
      work : qs('.is-work'),
      workSlider: qs('.work-slider'),
      workGrid: qs('.work-grid'),
      gridView: qs('.grid-view'),
      gridClose: qs('.grid-close'),
    }
  }

  components() {

    const { sniff } = store
    const { currentLocation } = this.core.taxi
    const {customEase} = this.core
    const { 
      deskMenu, 
      mobMenu, 
      footer, 
      open, 
      close, 
      gallery, 
      projects, 
      projectGallery, 
      work, 
      gridView, 
      workSlider, 
      workGrid, 
      gridClose 
    } = this.dom

    this.spliText = new Splits()

    this.menu = new Menu({
      deskMenu,
      mobMenu,
      footer,
      url: currentLocation.href,
      open,
      close,
    })
    
    if(projects) {
      this.projects = new HomeProjects({
        container: projects,
        sticky: qs('.projects-content'),
        items: qsa('.projects-item'),
      })
    }

    if(projectGallery) {
      this.projectGallery = new GalleryProjects({
        container: projectGallery,
        sticky: qs('.galleryProjects-content'),
        items: qsa('.galleryProjects-item'),
      })
    }

    if(gallery) {
      this.gallery = new StudioGallery({
        container: gallery,
        sticky: qs('.gallery-content'),
        items: qsa('.gallery-item'),
      })
    }

    if(work) {
      this.infiniteLoop = new InfiniteWorks({
        container: qs('.slider-content'),
        items: qsa('.slider-item'),
        bgs: qsa('.bg-item'),
        nav: qsa('.slider-line'),
        nr: qs('.nr-update'),
      })

      this.infiniteLoop.init()
      if(sniff.isDevice) {
        this.infiniteLoop.onResize() 
      } else {
        Emitter.on('textureReady', this.infiniteLoop.onResize)
      }
    }

    if(gridView) {

      const dots = qsa('.grid-dot')
      let shuffle = gsap.utils.shuffle(dots)
      let selected = shuffle.slice(15, 26)

      gsap.set(selected, {
        autoAlpha: 0,
        scale: 0.1,
      })

      gridView.addEventListener('mouseenter', (e)=> {
       
        gsap.to(selected, 
          {
          duration: 0.5,
          scale: 1,
          autoAlpha: 1,
          ease: "power1.inOut",
          stagger: {
            grid: [8,5],
            from: "random",
            amount: 0.5
          }
        })
        
        selected = shuffle.slice(15, 26)
      })

      gridView.addEventListener('mouseleave', (e)=> {
        shuffle = gsap.utils.shuffle(dots)
        selected = shuffle.slice(10, 20)
        gsap.to(selected, 
          {
            duration: 0.5,
            autoAlpha: 0,
            scale: 0.1,
            ease: "power1.inOut",
            stagger: {
              grid: [8,5],
              from: "random",
              amount: 0.5
            }
          })
      })

      gridView.addEventListener('click', (e)=> {

        const {index} = this.infiniteLoop
        const chars = qsa( '.grid-close .-t3:first-child .char-0')
        const projects = qsa('.item-img')
        const tl = gsap.timeline()

        workGrid.classList.remove('-hide')
        tl.to('header', {duration: 0.5, y: '-101%', ease: 'power2.inOut'})
        tl.to('.slider-content', { duration: 1, autoAlpha: 0, ease: 'power2.out' }, '-=0.5')
        tl.to('.slider-nav', { duration: 1, autoAlpha: 0, ease: 'power2.out' }, '-=1')
        tl.to('.slider-nr', { duration: 1, autoAlpha: 0, ease: 'power2.out' }, '-=1')
        tl.to('.slider-bg', { duration: 0.8, y: '-101%', ease: customEase }, '-=0.5')
        tl.to('.bg-crop', { duration: 0.8, y: '101%', ease: customEase }, '-=0.8')
        tl.fromTo('.work-grid', {y: '101%'}, { duration: 1, y: '0%', ease: customEase }, '-=0.5')
        tl.fromTo('.grid-content',{y: '-101%'}, { duration: 1, y: '-0%', ease: customEase, onComplete: ()=> {
          workSlider.classList.add('-hide')
          this.infiniteLoop.prevIndex = index
          this.infiniteLoop.settings.scroll = this.infiniteLoop.settings.scroll - this.infiniteLoop.settings.offset
        } }, '-=1')

        tl.from(projects, {duration: 0.8, y: '-101%', stagger: 0.05, ease: customEase }, '-=0.5')
        tl.from('.item-img .-img-crop', {duration: 0.8, y: '101%', stagger: 0.05,  ease: customEase }, '<')
        tl.from('.work-grid .-p1', {duration: 0.8, y: -10, autoAlpha: 0, stagger: 0.05,  ease: customEase }, '<')
        tl.fromTo(chars, { y: '101%'}, {duration: 0.8, y: '0%', stagger: 0.065, ease: customEase}, '-=1')

        if(this.infiniteLoop) {
          const els = this.infiniteLoop.els
          els.forEach((el)=> {
            tl.to(el.uniforms.uAlpha, { value: 0, duration: 0.5,  ease: 'power2.out'}, 0)
          })
        }

      })
    }

    if(gridClose) {

      gridClose.addEventListener('mouseenter', (e)=> {
        const chars1 = qsa( '.grid-close .-t3:first-child .char-0')
        const chars2 = qsa( '.grid-close .-t3:last-child .char-0')
        gsap.to(chars1,  {duration: 0.8, y: '101%', stagger: 0.065, ease: customEase})
        gsap.to(chars2,  {duration: 0.8, y: '0%', stagger: 0.065, ease: customEase})
      })

      gridClose.addEventListener('mouseleave', (e)=> {
        const chars1 = qsa( '.grid-close .-t3:first-child .char-0')
        const chars2 = qsa( '.grid-close .-t3:last-child .char-0')
        gsap.to(chars1, {duration: 0.8, y: '0%', stagger: 0.065, ease: customEase})
        gsap.to(chars2, {duration: 0.8, y: '-101%', stagger: 0.065, ease: customEase})
      })

      gridClose.addEventListener('click', (e)=> {
        gsap.to('header', {duration: 0.5, y: '0%', ease: 'power2.inOut'})
        const work = gsap.timeline({
          paused: true,
          onComplete: () => {      
         
          },
        })

        workSlider.classList.remove('-hide')
        gsap.set('.slider-nav', { autoAlpha: 1 })
        work.fromTo('.work-grid', { y: '0%'}, { duration: 1, y: '101%', ease: customEase })
        work.fromTo('.grid-content', {y: '0%'}, { duration: 1, y: '-101%', ease: customEase, onComplete: ()=> {
            this.infiniteLoop.snap()
            gsap.to('.slider-content', {  duration: 0.5, autoAlpha: 1 })
            workGrid.classList.add('-hide')     
          }
        }, '-=1')
        work.fromTo('.slider-bg', {y: '-101%'}, { duration: 1, y: '0%', ease: customEase }, '-=0.5')
        work.fromTo('.bg-crop', { y: '101%' }, { duration: 1, y: '-0%', ease: customEase}, '-=1')
        work.from('.slider-nav .slider-line', {duration: 0.5, scaleX: 0, stagger: 0.05, onComplete: (el)=> {
          gsap.set('.slider-nav .slider-line', {clearProps: 'all'})

        }}, '-=0.2')
        work.to('.slider-nr', { duration: 0.5, autoAlpha: 1, ease: 'power2.out' }, '-=1')
        work.to('.grid-view', {duration: 0.5, autoAlpha: 1, ease: 'power2.out'}, '-=0.4')
        work.to('.header', { duration: 0.5, y: '0%'})
        work.play()

        if(this.infiniteLoop) {
          const els = this.infiniteLoop.els
          els.forEach((el)=> {
            work.to(el.uniforms.uAlpha, { value: 1, duration: 0.5,  ease: 'power2.out'}, 1)
          })
        }


      })


      

    }

    this.forms = new Forms()
    this.reel = new Reel()
  
  }

  smooth() {

    const { work } = this.dom
    const smooth = qsa('[data-s-i]', this.el)

    if(!work) {
      formFocus()
      growTextarea()
      this.core.scroll.setScrollBounds()
      this.smoothScroll = new Smooth()
      this.smoothScroll.init(smooth)
      setTimeout(() => {
        this.smoothScroll.update()
      },1000)
    }
  }

  tick = (e) => {
  }

  resize = ()=> {
    this.isResizing = true
    this.core.scroll.setScrollBounds()
    this.projectGallery &&  this.projectGallery.resize()
    this.gallery &&  this.gallery.resize()
    this.infiniteLoop && this.infiniteLoop.resize()
    this.projects &&  this.projects.resize()
    ScrollTrigger.refresh()
    this.isResizing = false
  }

  on() {

    Emitter.on('tick', this.tick)
    Emitter.on('loaded', this.ready)
    Emitter.on('resize', this.resize )
    //Emitter.on('smooth:resize', this.smoothResize )
    Emitter.on('animateIn:ended', this.readyCompleted)
  }

  off() {

    Emitter.off('tick', this.tick)
    Emitter.off('loaded', this.ready)
    Emitter.off('resize', this.resize )
    //Emitter.off('smooth:resize', this.smoothResize )
    Emitter.off('animateIn:ended', this.readyCompleted)

   
  }

  onLeave() {
    this.off()
    const {sniff } = store
    store.flags.locked = true
    this.menu && this.menu.destroy()
    this.projects &&  this.projects.destroy()
    this.projectGallery &&  this.projectGallery.destroy()
    this.gallery &&  this.gallery.destroy()
    this.infiniteLoop && this.infiniteLoop.destroy()
    this.spliText.reverseContent(qs('.location-1 p'))
    this.spliText.reverseContent(qs('.location-2 p'))
    this.core.webgl.onLeave()

    if(!sniff.isDevice && this.infiniteLoop) Emitter.off('textureReady', this.infiniteLoop.onResize)
  }
  

  onLeaveCompleted() {
    ScrollTrigger.getAll().forEach((inst) => inst.kill(true))
  }
}

