

import store from "../store"
import { Core, Emitter } from "../core"
import { qs, bounds, lerp } from "../utils"
import normalizeWheel from 'normalize-wheel'
import { CustomEase } from '../vendor/CustomEase'
import gsap from "gsap"
import debounce from 'lodash/debounce'


export default class InfiniteLoop {

  constructor(obj={}) {

    this.core = new Core()
    this.custom =  CustomEase.create('custom', 'M0,0 C0.1,0 0.148,0.034 0.182,0.136 0.216,0.238 0.239,0.685 0.448,0.886 0.55,0.984 0.704,1 1,1')
    this.direction = 'down'

    this.dom = {
      items: obj.items,
      container: obj.container,
    }

    this.scroll = {
      ease: 0.05,
      current: 0,
      posy: 0,
      last: 0,

    }

    this.settings =  {
      fullHeight: 0,
      posY: 0,
      touchY: 0,
      totalItems: 0,
      scroll: 0,
      start: 0,
      last: 0
    }

    this.state ={
      resize: false,
      drag: false,
      dragging: false,

    }

    this.index = 0
    this.snapDebounce = debounce(this.snap, 200)

  }

  setup() {

    const { items } = this.dom
    const { vh } = store.sizes
    const totalItems = items.length - 1
    const rect = bounds(items[0])
    const fullHeight = rect.height * totalItems 

    this.settings.totalItems = totalItems
    this.settings.height = rect.height
    this.settings.fullHeight = fullHeight 

    gsap.set('body', { height: vh , overflow: 'hidden'  } )

  }

  tick = () => {
    this.run()
  }

  run() {
    const {items} = this.dom
    const { ease } = this.scroll
    const { height, fullHeight } = this.settings

    this.settings.posY = lerp( this.settings.posY, this.settings.scroll, ease)

   
    if (this.settings.posY > this.settings.last) {
      this.direction = 'up'
    } else {
      this.direction = 'down'
    }


    gsap.set(items, { 
      y: (i) => {
        return i * height + this.settings.posY
      },
      modifiers: {
        y: (y) => {
          const s = gsap.utils.wrap(-height , fullHeight, parseInt(y))
          return `${s}px`
        }
      }
    })

    store.infiniteLoop = this.settings.posY
    this.settings.last = this.settings.posY
  }


  mousewheel = (e) => {

    const wheel =  normalizeWheel(e)
   this.move(wheel)
  }

  mousedown = (e) => {
    const { container } = this.dom
    this.state.drag = true
    container.classList.add('-dragging')

    const y = e.clientY || e.touches[0].clientY
    const x = e.clientX || e.touches[0].clientX

    this.settings.start -= y
  }

  mousemove = (e) => {
    if (!this.state.drag) return
    this.touchmove(e)
  }

  mouseup = (e) => {
    e.stopPropagation()
    const { items, container } = this.dom
    this.state.drag = false

    container.classList.remove('-dragging')

    setTimeout(() => {
      for (let i = 0; i < items.length; i++) {
        this.dom.items[i].style.pointerEvents = ''
      }
    },300)

    this.settings.start = this.settings.touchY

  }

  touchmove(e) {
    const { items } = this.dom

    for (let i = 0; i < items.length; i++) {
      this.dom.items[i].style.pointerEvents = 'none'
    }

    this.settings.touchY = e.clientY || e.touches[0].clientY
    this.settings.scroll -= (this.settings.touchY - this.settings.start) * .1
   
    this.snapDebounce()
  }

  move(wheel) {
    this.settings.scroll -= wheel.pixelY
    this.snapDebounce()
  }


  keydown = (e) => {
   const key = e.keyCode || e.which
   this.down(key) 
  }

  down(key) {

    const { height } = this.settings

   if(key === 40 || key === 39 || key === 32) {
    this.settings.scroll -= height 
    this.snap()
   }

   if(key === 38 || key === 37) {
    this.settings.scroll += height 
    this.snap()
   }
  }


  snap() {

    const { height } = this.settings

    if(this.direction === 'down') {

      if(this.settings.scroll > 0)  {
        this.index = Math.floor(Math.abs(this.settings.scroll) / ( height   ))
      } else {
        this.index = Math.ceil(Math.abs(this.settings.scroll) / ( height   ))
      }
    } else {
      this.index = Math.round(Math.abs(this.settings.scroll) / height)
    }
   
    if(this.settings.scroll < 0) {
      this.settings.scroll = -(this.index  * height )
    } else {
      this.settings.scroll = (this.index  * height )
    }
  }

  on() {

    const { container } = this.dom

    Emitter.on('tick', this.tick)
    Emitter.on('resize', this.onResize)

    container.addEventListener('mousewheel', this.mousewheel)
    window.addEventListener('keydown', this.keydown)

    if (!store.flags.smooth) {
      container.addEventListener('touchstart', this.mousedown)
      container.addEventListener('touchmove', this.mousemove)
      container.addEventListener('touchend', this.mouseup)
      container.addEventListener('mousedown', this.mousedown)
      container.addEventListener('mousemove', this.mousemove)
      container.addEventListener('mouseleave', this.mouseup)
      container.addEventListener('mouseup', this.mouseup)
    }

  }

  off() {

    const { container } = this.dom

    Emitter.off('tick', this.tick)
    Emitter.off('resize', this.onResize)

    container.removeEventListener('mousewheel', this.mousewheel)
    window.removeEventListener('keydown', this.keydown)

    if (!store.flags.smooth) {
      container.removeEventListener('touchstart', this.mousedown)
      container.removeEventListener('touchmove', this.mousemove)
      container.removeEventListener('touchend', this.mouseup)
      container.removeEventListener('mousedown', this.mousedown)
      container.removeEventListener('mousemove', this.mousemove)
      container.removeEventListener('mouseleave', this.mouseup)
      container.removeEventListener('mouseup', this.mouseup)
    }
  }

  resize()  {
    this.state.resize = true
    this.scroll.current = 0
    this.scroll.posy = 0
    this.scroll.last = 0
    this.settings.posY = 0
    this.settings.touchY = 0
    this.settings.scroll = 0
    this.settings.start = 0
    this.settings.last = 0
    this.setup()
    this.state.resize = false
  }

  onResize = () => {
    this.resize()
  }
  

  destroy() {
    this.off()
    gsap.set('body', { clearProps: 'all'  } )
  
  }

  init() {
    this.setup()
    this.on()
  }

}