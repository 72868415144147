import * as THREE from 'three'
import store from '../store'
import { Core } from '../core'
import WebGlManager from './WebGlManager'
import GLObject from './GLObject'
import { qsa } from '../utils'
import gsap from 'gsap'

const hPlaneGeometry = new THREE.PlaneGeometry(1, 1)

const vertex = `
  precision mediump float;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

const fragment = `
  precision mediump float;
  varying vec2 vUv;
  uniform vec2 uRepeat;
  uniform float uHover;
  uniform sampler2D texturePrev;
  uniform sampler2D textureNext;
  uniform sampler2D uDisplacement;
  uniform vec2 uRes;
  uniform float uAlpha;

  float random(vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
  }

  float noise(vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));
    vec2 u = f * f * (3.0 - 2.0 * f);
    return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
  }

  vec2 randomDirection(vec2 st) {
    float angle = random(st) * 2.0 * 3.141592653589793;
    return vec2(cos(angle), sin(angle));
  }

  void main() {

    vec2 uv = vUv;
    vec2 res = uRes;
    vec2 st = gl_FragCoord.xy / res.xy - vec2(0.5);
    st.y *= uRes.y / uRes.x;


    float grid = 16.0 / 256.0;
    float staticTime = random(vec2(10.0,20.0));

    vec2 gridUV = vec2( floor(uv.x / grid),  floor(uv.y / grid)  );

    float staticNoise = noise(gridUV + staticTime);
    float noise = step(0.5, staticNoise);

    float displacementFactor = 0.15;
    float displacement_1 = (noise * 2.0 - 1.0) * uHover * displacementFactor; 
    float displacement_2 = (noise * 2.0 - 1.0) * (1. - uHover) * displacementFactor; 

    vec2 direction = randomDirection(gridUV);
    vec2 displacedUV_1 = uv + direction * displacement_1;
    vec2 displacedUV_2 = uv + direction * displacement_2;


    vec4 color_0 = texture2D(texturePrev, vUv);
    vec4 color_1 = texture2D(texturePrev, displacedUV_1);
    vec4 color_2 = texture2D(textureNext, displacedUV_2);
   
    
    vec4 color = mix(color_1, color_2, uHover);

    gl_FragColor = vec4(color.rgb, uAlpha);
  }
`

export default class GalleryGL extends GLObject {
  constructor(opts = {}) {
    super()
    
    this.opts = opts
    this.name = 'gallery'
    this.manager = new WebGlManager()
    this.core = new Core()
    this.taxi = this.core.taxi
    this.time = this.manager.time
    this.scene = this.manager.scene
    this.resources = this.manager.resources
    this.state = {}
    this.state.posY = 0
    this.state.posX = 0
    this.state.index = {current: 0, next: 0}

   
  }

  init(el) {
    super.init(el)
    this.containerSize = new THREE.Vector2(el.clientWidth, el.clientHeight)
    this.createFrontMesh()
    this.onEnter()
  }

  createFrontMesh() {
    const textures = [];
    const keys = Object.keys(this.resources.items)
    const displacementMap = this.resources.items.displacementMap

    for (const key of keys) {
      if( this.resources.items[key].name == 'gallery') {
        textures.push(this.resources.items[key])
      }
    }

    this.uniforms =  {
      texturePrev: { value: textures[0] },
      textureNext: { value: textures[1] },
      uDisplacement: { value: displacementMap },
      uHover: { value: 0 },
      uRes: {value:new THREE.Vector2( store.sizes.vw, store.sizes.vh )},
      uAlpha: { value: 0 },
    }

    this.material = new THREE.ShaderMaterial({
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: this.uniforms,
      transparent: true
    })

    this.mesh = new THREE.Mesh(hPlaneGeometry, this.material)
    this.add(this.mesh)
  }

  resize() {
    super.resize()
    this.bounds.top = this.bounds.top - this.state.posY
  }

  onEnter() {
    this.scene.add(this)
    gsap.to(this.uniforms.uAlpha, { duration: 1, delay: 0.5, value: 1})
  }

  animateOut() {
    gsap.to(this.uniforms.uAlpha, {
      value: 0,
      duration: 0.25,
      onComplete: () => {
        this.onLeave()
      }
    })
  }

  onLeave() {
    this.position.y = 0
    this.scene.remove(this)
  }

  updatePosition(current, last) {
    let scroll = -( store.scroll.y - this.state.posY )
    this.position.y = this.viewSize.height / 2 - this.scale.y / 2
    this.position.y -= (( (this.bounds.top) + (scroll) ) / this.sizes.height ) * this.viewSize.height
  }

  update() {
  }
  
}
