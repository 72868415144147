import store from '../store'
import Emitter from './Emitter'
import { Sniffer, lerp } from '../utils'
import { gsap } from 'gsap'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

export default class GlobalVRaf {
  constructor() {
    gsap.registerPlugin(ScrollTrigger)

    this.scroll = {
      target: 0,
      current: 0,
      rounded: 0,
      ease: 0.115,
    }

    this.mouse = {
      x: 0,
      y: 0,
      target: null,
      lastX: 0, 
      lastY: 0,
      distances: [],
      timeIntervals: [],
      strength: {
        speed: 0,
        acceleration: 0,
      },
      maxHistory: 10 
    }

    this.setScrollTrigger()
  }

  tick = () => {
    const { target, current, ease } = this.scroll
    this.scroll.current = lerp(current, target, ease)
    this.scroll.rounded = Math.round(current * 100) / 100
    this.diff = (target - current) * 0.005

    Emitter.emit('tick', {
      target: target,
      current: this.smooth(),
      mouse: this.mouse,
      diff: this.diff,
    })

    store.scroll.y = this.smooth()

    ScrollTrigger.update()
  }

  clamp() {
    this.scroll.target = Math.min(
      Math.max(this.scroll.target, 0),
      store.sizes.h,
    )
  }

  smooth() {
    if (!Sniffer.sniff.isDevice) {
      return this.scroll.rounded
    } else {
      return window.pageYOffset
    }
  }

  onScroll = ({ y }) => {
    if (store.flags.locked) return
    this.scroll.target += y
    this.clamp()
  }

  setScroll(offset) {

    if (store.flags.smooth) {
      gsap.set(this.scroll, {
        target: offset,
        current: offset,
        rounded: offset,
      })
      gsap.to(this, {
        original: offset,
        duration: 1,
        ease: 'expo.inOut',
      })
    } else {
      window.scrollTo({
        top: offset,
        left: 0,
      })
    }

  }

  scrollTo(offset) {
    
    if (store.flags.smooth) {
      
      gsap.to(this.scroll, {
        target: offset,
        duration: 1,
        ease: 'expo.inOut',
      })
    } else {
      window.scrollTo({
        top: offset,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  getAverageSpeed() {
    const { distances, timeIntervals } = this.mouse;
    if (distances.length === 0 || timeIntervals.length === 0) return 0;
    const totalDistance = distances.reduce((acc, dist) => acc + dist, 0);
    const totalTime = timeIntervals.reduce((acc, interval) => acc + interval, 0);
    return totalDistance / (totalTime / 1000); // pixels per second
  }

  getAcceleration() {
    const { distances, timeIntervals } = this.mouse;
    if (distances.length < 2 || timeIntervals.length < 2) return 0;
    const speeds = distances.map((dist, i) => dist / (timeIntervals[i] / 1000));
    const accelerations = speeds.map((speed, i, arr) => i > 0 ? (speed - arr[i - 1]) / (timeIntervals[i] / 1000) : 0);
    return accelerations.reduce((acc, accel) => acc + accel, 0) / (accelerations.length - 1); // exclude first undefined acceleration
  }

  getStrength() {
    const speed = this.getAverageSpeed();
    const acceleration = this.getAcceleration();
    return { speed, acceleration };
  }

  move = ({ x, y, target }) => {

    const { distances, timeIntervals, maxHistory } = this.mouse;

    const currentTime = new Date().getTime();
    const { lastX, lastY, lastTime } = this.mouse;

    if (lastX !== null && lastY !== null && lastTime !== null) {
      const deltaX = x - lastX;
      const deltaY = y - lastY;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const timeInterval = currentTime - lastTime;

      if (!isNaN(distance) && timeInterval > 0) {
        distances.push(distance);
        timeIntervals.push(timeInterval);

        // Limit the history size
        if (distances.length > maxHistory) distances.shift();
        if (timeIntervals.length > maxHistory) timeIntervals.shift();
      }
    }

    this.mouse.x = x;
    this.mouse.y = y;
    this.mouse.target = target;
    this.mouse.strength = this.getStrength();

    this.mouse.lastX = x;
    this.mouse.lastY = y;
    this.mouse.lastTime = currentTime;   
  }

  reset() {
    this.scroll.target = this.scroll.current = this.scroll.rounded = 0
  }

  setScrollTrigger() {
    const { body, sizes } = store

    ScrollTrigger.defaults({
      scroller: body,
    })

    ScrollTrigger.scrollerProxy(body, {
      scrollTop: () => {
        return this.smooth()
      },
      getBoundingClientRect() {
        return { top: 0, left: 0, width: sizes.vw, height: sizes.vh }
      },
    })
  }
  

  on() {
    gsap.ticker.add(this.tick)
    Emitter.on('scroll', this.onScroll)
    Emitter.on('mousemove', this.move)
  }
}
